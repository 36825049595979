import { ReactElement } from 'react';
import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { validateProductNameUniqueness } from 'utils/productDefinition';
import { useQuery, skipToken } from '@tanstack/react-query';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { useLogAndShowNotification } from '@mid-react-common/common';
import { MetaInfoPath } from 'mid-types';
import { ProductNameUniquenessErrorTooltipContent } from './ProductNameUniquenessErrorTooltipContent';
import text from '../../../publisher.text.json';

interface UseProductNameUniquenessValidationProps {
  projectId?: string;
  productName: string;
  selectedFolder: MetaInfoPath;
  allProducts?: DynamicContent[];
}

interface UseProductNameUniquenessValidationState {
  productNameUniquenessErrorMessage: string | null;
  productNameUniquenessTooltipText: ReactElement | null;
}

export const useProductNameUniquenessValidation = ({
  projectId,
  productName,
  selectedFolder,
  allProducts,
}: UseProductNameUniquenessValidationProps): UseProductNameUniquenessValidationState => {
  const { errorMessage, productFolderId, productFolderPath } = validateProductNameUniqueness(
    productName,
    selectedFolder,
    allProducts,
  );

  const {
    data: productFolderTitlesBreadcrumbs,
    error: productFolderTreeError,
    isFetching: productFolderTreeFetching,
  } = useQuery({
    queryKey: ['productFolderTitlesBreadcrumbs', productFolderId],
    queryFn: projectId
      ? async () =>
          getForgeApiServiceInstance().getFolderTitlesBreadcrumbs(projectId, productFolderId, productFolderPath.split('/'))
      : skipToken,
    enabled: !!errorMessage && !!projectId,
  });

  useLogAndShowNotification(productFolderTreeError, text.failedToGetOriginalProductFolderPath);

  let productNameUniquenessTooltipText = null;

  // the tooltip text makes sense only if the validation against the product name uniqueness failed
  if (errorMessage) {
    // if error when fetching the product folder tree, show the error message within the tooltip
    if (productFolderTreeError) {
      productNameUniquenessTooltipText = <>{text.failedToGetOriginalProductFolderPath}</>;
    } else {
      // if no error message but the folder tree is loading or the breadcrumbs are not available, show a Loading... message
      productNameUniquenessTooltipText =
        productFolderTreeFetching || !productFolderTitlesBreadcrumbs ? (
          <>{text.originalProductFolderPathLoading}</>
        ) : (
          <>
            <ProductNameUniquenessErrorTooltipContent productFolderTitlesBreadcrumbs={productFolderTitlesBreadcrumbs} />
          </>
        );
    }
  }

  return {
    productNameUniquenessErrorMessage: errorMessage,
    productNameUniquenessTooltipText,
  };
};
