import { blocklyKinds } from '../constants';
import text from 'publisher.text.json';
import { controlBlock, groupBlock } from './FormCodeblocks.constants';
import { ToolboxDefinition } from 'blockly/core/utils/toolbox';

export const getFormCodeBlocksToolbox = (draggable: boolean): ToolboxDefinition => ({
  kind: blocklyKinds.CATEGORY_TOOLBOX,
  contents: [
    {
      kind: blocklyKinds.CATEGORY,
      name: text.formCodeblocksCategoryName,
      contents: [
        {
          kind: blocklyKinds.BLOCK,
          type: controlBlock,
          enabled: draggable,
        },
        {
          kind: blocklyKinds.BLOCK,
          type: groupBlock,
          enabled: draggable,
        },
      ],
    },
  ],
});
