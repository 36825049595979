import { GridColDef } from '@mui/x-data-grid';
import text from 'publisher.text.json';
import { FlexContainer, Text, midTheme } from '@mid-react-common/common';
import { pathSeparators } from 'mid-utils';
import { DrawingImageWrapper } from '../SourceContentTab.styles';

export const getColumns = (): GridColDef[] => [
  {
    field: 'filename',
    headerName: text.drawingsTableHeaderName,
    renderCell: (params) => (
      <FlexContainer alignItems="center" gap={midTheme.var.marginBase * 2}>
        <DrawingImageWrapper src={`data:image/jpeg;base64,${params.value.base64}`} alt={text.previewImageAltText} />
        <Text>{params.value.relativePath}</Text>
      </FlexContainer>
    ),
    flex: 1,
  },
];

export const extractRelativeFilePath = (topLevelFolder: string, absoluteFilePath: string): string | null => {
  const folderLengthWithoutPathSeparator =
    topLevelFolder.endsWith(pathSeparators.FORWARD_SLASH) || topLevelFolder.endsWith(pathSeparators.BACKSLASH)
      ? topLevelFolder.length - 1
      : topLevelFolder.length;
  return absoluteFilePath.startsWith(topLevelFolder) ? absoluteFilePath.slice(folderLengthWithoutPathSeparator) : null;
};
