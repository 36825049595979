import { BlockSvg, ContextMenuRegistry, serialization, WorkspaceSvg } from 'blockly';
import { CONTEXT_MENU_DISABLED, CONTEXT_MENU_ENABLED, CONTEXT_MENU_EXPORT, CONTEXT_MENU_HIDDEN } from '../constants';
import text from 'publisher.text.json';
import { BlocklyPluginPreconditionReturn } from '../types';

function addExportableBlocks(block: BlockSvg, deleteList: BlockSvg[]) {
  if (block.isDeletable()) {
    Array.prototype.push.apply(deleteList, block.getDescendants(false));
  } else {
    const children = block.getChildren(false);
    for (let i = 0; i < children.length; i++) {
      addExportableBlocks(children[i], deleteList);
    }
  }
}

function getExportableBlocks(workspace: WorkspaceSvg): BlockSvg[] {
  const exportableBlocks: BlockSvg[] = [];
  const topBlocks = workspace.getTopBlocks(true);
  for (let i = 0; i < topBlocks.length; i++) {
    addExportableBlocks(topBlocks[i], exportableBlocks);
  }
  return exportableBlocks;
}

export const initializeExportPlugin = (workspace: WorkspaceSvg, onExport: (serializedWorkspace: string) => void): void => {
  if (!workspace) {
    return;
  }

  /*
   * Context Menu Override
   */
  const ContextMenuExport = {
    displayText: (scope: ContextMenuRegistry.Scope): string => {
      if (!scope.workspace) {
        return '';
      }

      const exportableBlocks = getExportableBlocks(scope.workspace);

      return text.exportBlocksContextMenuItem.replace('{BLOCKS_COUNT}', exportableBlocks.length.toString());
    },
    preconditionFn: (scope: ContextMenuRegistry.Scope): BlocklyPluginPreconditionReturn => {
      // do not show the import context menu item on a Form workspace or a Flyout sidebar
      if (scope.workspace && !scope.workspace.options.plugins[CONTEXT_MENU_EXPORT]) {
        return CONTEXT_MENU_HIDDEN;
      }

      if (!scope.workspace) {
        return CONTEXT_MENU_DISABLED;
      }

      if (!getExportableBlocks(scope.workspace).length) {
        return CONTEXT_MENU_HIDDEN;
      }

      return CONTEXT_MENU_ENABLED;
    },
    callback: (scope: ContextMenuRegistry.Scope) => {
      if (!scope.workspace) {
        return;
      }

      const serializedWorkspace = serialization.workspaces.save(scope.workspace);

      onExport(JSON.stringify(serializedWorkspace));
    },
    scopeType: ContextMenuRegistry.ScopeType.WORKSPACE,
    id: CONTEXT_MENU_EXPORT,
    weight: 1,
  };

  // prevent double registering in cypress tests
  if (ContextMenuRegistry.registry.getItem(CONTEXT_MENU_EXPORT)) {
    ContextMenuRegistry.registry.unregister(CONTEXT_MENU_EXPORT);
  }

  ContextMenuRegistry.registry.register(ContextMenuExport);
};
