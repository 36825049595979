import { useContext, useState } from 'react';
import { useAddinVersion } from '@mid-react-common/addins';
import {
  AboutInfoMenu,
  initialModalState,
  ModalContext,
  NOTIFICATION_STATUSES,
  NotificationContext,
  TopHeader,
  unsavedChangesWarningMessage,
} from '@mid-react-common/common';
import text from 'publisher.text.json';
import { openMidWebapp, PublishStatus } from 'mid-addin-lib';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import TabProgressContext from '../../context/TabProgressStore/TabProgress.context';
import { validateProductDefinitionName } from '../../utils/productDefinition';
import Initial from './SubHeaders/Initial';
import ProductDefinitionConfigurationHeader from './SubHeaders/ProductDefinitionConfigurationHeader';
import PublishingHeader from './SubHeaders/PublishingHeader';
import { PublishLocationScreenHeader } from './SubHeaders/PublishLocationScreenHeader';
import { productDefinitionActions, useProductDefinitionStore } from '../../context/DataStore/productDefinitionStore';
import { publisherStoreActions, usePublisherDataStore } from '../../context/DataStore/PublisherDataStore';
import { useShallow } from 'zustand/react/shallow';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { checkPublishPrerequisites } from 'components/Publishing/publishing.utils';
import { CustomizerDataContext } from 'context/Customizers/customizerData.context';
import CustomComponentsContext from 'context/Customizers/customizerComponents.context';

const Header: React.FC = (): JSX.Element => {
  const { initialProductDefinitionData } = useContext(CustomizerDataContext);
  const { PublishPrereqsComponent, webComponentVersion } = useContext(CustomComponentsContext);
  const { currentScreen, setCurrentScreen } = useContext(NavigationContext);
  const { enablePublishChecks } = useFlags();
  const currentProductDefinition = useProductDefinitionStore.getState();

  const { productDefinitionHasUnsavedChanges, productDefinitionPublishStatus } = usePublisherDataStore(
    useShallow((state) => ({
      productDefinitionHasUnsavedChanges: state.productDefinitionHasUnsavedChanges,
      productDefinitionPublishStatus: state.currentPublishStatus,
    })),
  );

  const { resetToInitialActiveTab } = useContext(TabProgressContext);
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);

  const { addinVersion, desktopApplicationVersion } = useAddinVersion();

  const handleNavigateToProductSelectionPage = () => {
    productDefinitionActions.resetProductDefinition(initialProductDefinitionData);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_SELECTION);
    publisherStoreActions.setCurrentPublishStatus(PublishStatus.IDLE);
  };

  const [isValidatingPublish, setIsValidatingPublish] = useState(false);
  const handlePublishClick = async (): Promise<void> => {
    setIsValidatingPublish(true);
    const productDefinitionValidationResult = await validateProductDefinitionName(
      currentProductDefinition.name,
      currentProductDefinition.id,
    );
    if (productDefinitionValidationResult.error) {
      setIsValidatingPublish(false);
      showNotification({
        message: productDefinitionValidationResult.cause || '',
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      resetToInitialActiveTab();
      return;
    }

    if (enablePublishChecks && PublishPrereqsComponent) {
      const prerequisiteResult = await checkPublishPrerequisites(currentProductDefinition);
      if (prerequisiteResult) {
        publisherStoreActions.setPublishPrerequisiteErrors(prerequisiteResult);
        publisherStoreActions.setCurrentPublishStatus(PublishStatus.INVALID);
      }
    }
    setIsValidatingPublish(false);
    setCurrentScreen(Screens.PUBLISHING);
  };

  const handleOpenProductDefinitionsSelectionClick = (): void => {
    if (productDefinitionHasUnsavedChanges) {
      setModalState({
        ...initialModalState,
        isOpen: true,
        title: text.unsavedChanges,
        message: unsavedChangesWarningMessage,
        onConfirmCallback: handleNavigateToProductSelectionPage,
      });
    } else {
      handleNavigateToProductSelectionPage();
    }
  };

  const handleEditProductDefinitionClick = () => {
    publisherStoreActions.setCurrentPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
  };

  if (
    currentScreen === Screens.PUBLISHING &&
    productDefinitionPublishStatus !== PublishStatus.IDLE &&
    productDefinitionPublishStatus !== PublishStatus.INVALID
  ) {
    return (
      <>
        {productDefinitionPublishStatus === PublishStatus.LOADING && (
          <PublishingHeader title={text.headerPublishingInProgress} titleColor="primary.main" />
        )}
        {productDefinitionPublishStatus === PublishStatus.COMPLETE && (
          <PublishingHeader
            title={text.headerPublishingComplete}
            subtitle={text.subHeaderPublishingComplete}
            webComponentVersion={webComponentVersion}
            titleColor="success.main"
            addinVersion={addinVersion}
            desktopApplicationVersion={desktopApplicationVersion}
            handleGoBackToProductDefinitions={handleNavigateToProductSelectionPage}
          />
        )}
        {productDefinitionPublishStatus === PublishStatus.FAILURE && (
          <PublishingHeader
            title={text.headerPublishingFailed}
            subtitle={text.subHeaderPublishingFailed}
            webComponentVersion={webComponentVersion}
            titleColor="error.main"
            addinVersion={addinVersion}
            desktopApplicationVersion={desktopApplicationVersion}
            handleGoBackToProductDefinitions={handleNavigateToProductSelectionPage}
          />
        )}
      </>
    );
  }

  return (
    <TopHeader className={productDefinitionPublishStatus === PublishStatus.INVALID ? 'mid-bg-shadow' : ''}>
      {currentScreen === Screens.PRODUCT_DEFINITION_SELECTION && <Initial handleMidWebPortalClick={openMidWebapp} />}
      {currentScreen === Screens.PRODUCT_DEFINITION_CONFIGURATION && (
        <ProductDefinitionConfigurationHeader
          handleOpenProductDefinitionsSelectionClick={handleOpenProductDefinitionsSelectionClick}
          handlePublishClick={handlePublishClick}
          isValidatingPublish={isValidatingPublish}
        />
      )}
      {currentScreen === Screens.PUBLISHING && productDefinitionPublishStatus === PublishStatus.IDLE && (
        <PublishLocationScreenHeader
          handleEditProductDefinitionClick={handleEditProductDefinitionClick}
          handleOpenProductDefinitionsSelectionClick={handleOpenProductDefinitionsSelectionClick}
          showPublishButton
        />
      )}
      {currentScreen === Screens.PUBLISHING && productDefinitionPublishStatus === PublishStatus.INVALID && (
        <PublishLocationScreenHeader
          handleEditProductDefinitionClick={handleEditProductDefinitionClick}
          handleOpenProductDefinitionsSelectionClick={handleOpenProductDefinitionsSelectionClick}
        />
      )}
      <AboutInfoMenu
        isTargetBlank
        webComponentVersion={webComponentVersion}
        addinVersion={addinVersion}
        desktopApplicationVersion={desktopApplicationVersion}
      />
    </TopHeader>
  );
};

export default Header;
