import { ProductDefinitionInputParameter, PublishStatus } from 'mid-addin-lib';
import { CodeblocksWorkspaceType } from '../../components/Rules/types';
import { create } from 'zustand';
import { getUUID } from 'mid-utils';
import { ProductDefinitionEngine } from 'mid-types';

export const initialPublishStatus = PublishStatus.IDLE;

export type PublisherDataStore = {
  productDefinitionHasUnsavedChanges: boolean;
  currentPublishStatus: PublishStatus;
  isErronousProductDefinitionName: {
    error: boolean;
    cause: string | null;
  };
  latestWorkspaceSelected: CodeblocksWorkspaceType | undefined;
  isPublishingDisabled: boolean;
  recentlyAdoptedInputs: ProductDefinitionInputParameter[];
  backpackContents: string[];
  areDrawingFilesFetched: boolean;
  publishingDisabledCause: string | null;
  publishingIncompatibleAppEngine: ProductDefinitionEngine | null;
  publishPrerequisiteErrors: {
    formError: boolean | null;
    productDefinitionDefaultValuesError: boolean | null;
    inventorModelDefaultValuesError: boolean | null;
    documentSavedError: boolean | null;
  };
  sessionId: string;
};

export const usePublisherDataStore = create<PublisherDataStore>(() => ({
  currentPublishStatus: initialPublishStatus,
  isErronousProductDefinitionName: {
    error: false,
    cause: null,
  },
  productDefinitionHasUnsavedChanges: false,
  latestWorkspaceSelected: CodeblocksWorkspaceType.FORM,
  isPublishingDisabled: false,
  recentlyAdoptedInputs: [],
  backpackContents: [],
  areDrawingFilesFetched: false,
  publishingDisabledCause: null,
  publishingIncompatibleAppEngine: null,
  publishPrerequisiteErrors: {
    formError: false,
    productDefinitionDefaultValuesError: false,
    inventorModelDefaultValuesError: false,
    documentSavedError: false,
  },
  sessionId: getUUID(),
}));

export const publisherStoreActions = {
  setCurrentPublishStatus: (currentPublishStatus: PublishStatus): void =>
    usePublisherDataStore.setState({ currentPublishStatus }),
  setIsErronousProductDefinitionName: (isErronousProductDefinitionName: { error: boolean; cause: string | null }): void =>
    usePublisherDataStore.setState({ isErronousProductDefinitionName }),
  setLatestWorkspaceSelected: (latestWorkspaceSelected: CodeblocksWorkspaceType | undefined): void =>
    usePublisherDataStore.setState({ latestWorkspaceSelected }),
  setRecentlyAdoptedInputs: (recentlyAdoptedInputs: ProductDefinitionInputParameter[]): void =>
    usePublisherDataStore.setState({ recentlyAdoptedInputs }),
  setAreDrawingFilesFetched: (areDrawingFilesFetched: boolean): void =>
    usePublisherDataStore.setState({ areDrawingFilesFetched }),
  setBackpackContents: (backpackContents: string[]): void => usePublisherDataStore.setState({ backpackContents }),
  setIsPublishingDisabled: (isPublishingDisabled: boolean): void => usePublisherDataStore.setState({ isPublishingDisabled }),
  setProductDefinitionHasUnsavedChanges: (productDefinitionHasUnsavedChanges: boolean): void =>
    usePublisherDataStore.setState({ productDefinitionHasUnsavedChanges }),
  setPublishingDisabledCause: (publishingDisabledCause: string | null): void =>
    usePublisherDataStore.setState({ publishingDisabledCause }),
  setPublishPrerequisiteErrors: (publishPrerequisiteErrors: PublisherDataStore['publishPrerequisiteErrors']): void =>
    usePublisherDataStore.setState({ publishPrerequisiteErrors }),
  setPublishingIncompatibleAppEngine: (publishingIncompatibleAppEngine: ProductDefinitionEngine | null): void =>
    usePublisherDataStore.setState({ publishingIncompatibleAppEngine }),
};
