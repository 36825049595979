import {
  GridColDef,
  GridComparatorFn,
  gridStringOrNumberComparator,
  gridNumberComparator,
  gridDateComparator,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import text from 'publisher.text.json';
import { ProductDefinitionListTableCellWrapper } from './ProductDefinitionTable.styles';
import { DATETIME_FORMAT } from '@mid-react-common/common';

const productDefinitionNameComparator: GridComparatorFn = (cellValue1, cellValue2, row1, row2) => {
  const duplicateNumberRegex = /\((\d+)\)$/;
  const [pdName1, duplicateNumber1] = cellValue1.split(duplicateNumberRegex);
  const [pdName2, duplicateNumber2] = cellValue2.split(duplicateNumberRegex);
  if (pdName1.trim() !== pdName2.trim()) {
    return gridStringOrNumberComparator(pdName1, pdName2, row1, row2);
  }
  return gridNumberComparator(Number(duplicateNumber1), Number(duplicateNumber2), row1, row2);
};

export const getColumns = (enableStaticContent?: boolean): GridColDef[] => [
  {
    headerClassName: 'product-definition-table-header',
    field: 'productDefinitionName',
    valueGetter: (params) => params.row.productDefinitionName,
    headerName: text.productDefinitionTableColumnName,
    flex: 2,
    sortComparator: productDefinitionNameComparator,
    renderCell: (params) => (
      <Tooltip title={params.value} placement="top-start" arrow>
        <ProductDefinitionListTableCellWrapper>{params.value}</ProductDefinitionListTableCellWrapper>
      </Tooltip>
    ),
  },
  {
    headerClassName: 'product-definition-table-header',
    field: 'numberOfInputs',
    headerName: text.productDefinitionTableColumnNumberOfInputs,
    flex: 1,
  },
  {
    headerClassName: 'product-definition-table-header',
    field: 'lastPublishLocation',
    headerName: text.productDefinitionTableColumnLastPublishLocation,
    flex: 3,
    renderCell: (params) => (
      <Tooltip title={params.value} placement="top-start" arrow>
        <ProductDefinitionListTableCellWrapper>{params.value}</ProductDefinitionListTableCellWrapper>
      </Tooltip>
    ),
  },
  ...(enableStaticContent
    ? [
        {
          headerClassName: 'product-definition-table-header',
          field: 'lastPublishedAs',
          headerName: text.productDefinitionTableColumnLastPublishedAs,
          flex: 1,
        },
      ]
    : []),
  {
    field: 'productName',
    headerName: text.productDefinitionTableColumnProductName,
    flex: 2,
    renderCell: (params) => (
      <Tooltip title={params.value} placement="top-start" arrow>
        <ProductDefinitionListTableCellWrapper>{params.value}</ProductDefinitionListTableCellWrapper>
      </Tooltip>
    ),
  },
  {
    headerClassName: 'product-definition-table-header',
    field: 'lastSaved',
    type: 'dateTime',
    headerName: text.productDefinitionTableColumnLastSaved,
    flex: 2,
    sortComparator: gridDateComparator,
    renderCell: ({ value }) => format(value, DATETIME_FORMAT),
  },
];

export type PRODUCT_DEFINITION_TABLE_ACTION =
  (typeof PRODUCT_DEFINITION_TABLE_ACTIONS)[keyof typeof PRODUCT_DEFINITION_TABLE_ACTIONS];

export const PRODUCT_DEFINITION_TABLE_ACTIONS = {
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
} as const;
