import { useCallback, useContext, useEffect, useState } from 'react';
import { NOTIFICATION_STATUSES, NotificationContext } from '@mid-react-common/common';
import { getDcApiServiceInstance } from 'mid-api-services';
import text from '../../publisher.text.json';
import isUndefined from 'lodash/isUndefined';
import { DynamicContent } from '@adsk/offsite-dc-sdk';

type UserReleaseNumberToBeProps = {
  productNameToPublish: string;
  projectId: string | undefined;
  products: DynamicContent[] | undefined;
  productsError: Error | null;
  productsLoading: boolean;
};

type UserReleaseNumberToBeState = {
  releaseNumberToBe: number | undefined;
  releaseNumberToBeLoading: boolean;
  shouldShowReleaseNumberToBe: boolean;
};

const FLICKERING_PREVENTION_TIMEOUT = 500;

const useReleaseNumberToBe = ({
  productNameToPublish,
  projectId,
  products,
  productsError,
  productsLoading,
}: UserReleaseNumberToBeProps): UserReleaseNumberToBeState => {
  const [releaseNumberToBe, setReleaseNumberToBe] = useState<number | undefined>();
  const [releaseNumberToBeLoading, setReleaseNumberToBeLoading] = useState<boolean>(false);
  const [releaseNumberToBeVisibility, setReleaseNumberToBeVisibility] = useState<boolean>(false);

  const { logAndShowNotification } = useContext(NotificationContext);

  const fetchReleaseToBeNumber = useCallback(
    async (projectId: string, products: DynamicContent[], productNameToPublish: string) => {
      const existingProduct = products?.find((product) => product.name === productNameToPublish);

      if (!existingProduct) {
        setReleaseNumberToBe(1);
        return;
      }

      try {
        setReleaseNumberToBeLoading(true);

        const releases = await getDcApiServiceInstance().getProductReleasesList({
          projectId,
          productId: existingProduct.contentId,
        });
        setReleaseNumberToBe(releases.length + 1);
      } catch (error) {
        logAndShowNotification({
          message: text.releaseNumberToBeFetchingFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
          error,
        });
      } finally {
        setReleaseNumberToBeLoading(false);
      }
    },
    [logAndShowNotification],
  );

  // fetch release number when project, product name or products array changes
  useEffect(() => {
    if (!products || !projectId) {
      return;
    }

    fetchReleaseToBeNumber(projectId, products, productNameToPublish);
  }, [projectId, products, fetchReleaseToBeNumber, productNameToPublish]);

  // reset release number when project, product name or products array changes to undefined
  useEffect(() => {
    if (!projectId || !productNameToPublish || !products) {
      setReleaseNumberToBe(undefined);
    }
  }, [productNameToPublish, products, projectId]);

  useEffect(() => {
    const visibility = !productsError && (!isUndefined(releaseNumberToBe) || productsLoading || releaseNumberToBeLoading);

    if (visibility) {
      setReleaseNumberToBeVisibility(true);
      return;
    }

    // when the project id is changed, there is a fraction of a second, when products array is reset but the products
    // loading process has not yet started. This leads to hiding of the ReleaseNumberToBe on the UI. After a few milliseconds
    // the products call gets initiated and the loading state is set to true. This leads to flickering.
    // the timeout is used to prevent this flickering
    const timeoutId = window.setTimeout(() => {
      setReleaseNumberToBeVisibility(false);
    }, FLICKERING_PREVENTION_TIMEOUT);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [productsError, productsLoading, releaseNumberToBe, releaseNumberToBeLoading]);

  return {
    releaseNumberToBe,
    releaseNumberToBeLoading,
    shouldShowReleaseNumberToBe: releaseNumberToBeVisibility,
  };
};

export default useReleaseNumberToBe;
