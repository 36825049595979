import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useTheme } from '@mui/material/styles';
import { IconWrapper } from '@mid-react-common/common';
import text from 'publisher.text.json';
import { drawingOutputTypes, DrawingThumbnail, PDF_FILE_TYPE } from 'mid-types';
import { useDrawingExportRows } from './useDrawingExportRows';
import { isOutputValid } from 'mid-utils';
import { DrawingPathText, DrawingThumbnailVisibleIcon } from '../Drawing2D.styles';

interface DrawingExportRowsProps {
  selectedDrawingThumbnailInDropdown: DrawingThumbnail | undefined;
}

const DrawingExportRows: React.FC<DrawingExportRowsProps> = ({ selectedDrawingThumbnailInDropdown }) => {
  const { drawingOutputList, handleDrawingOutputChange } = useDrawingExportRows();
  const theme = useTheme();

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name: drawingPath, value: outputType } = event.target;

    if (isOutputValid(outputType)) {
      handleDrawingOutputChange(drawingPath, outputType, checked);
    }
  };

  return (
    <>
      {drawingOutputList.map((drawing) => (
        <TableRow
          key={`${drawing.drawingPath}-${drawing.outputType}`}
          data-id={`${drawing.drawingPath}-${drawing.outputType}`}
        >
          <TableCell>
            {drawing.outputType !== drawingOutputTypes[PDF_FILE_TYPE] && (
              <p>
                <IconWrapper>
                  <InsertDriveFileIcon color="action" />
                </IconWrapper>
                <DrawingPathText>{drawing.drawingPath}</DrawingPathText>
                {selectedDrawingThumbnailInDropdown?.relativePath === drawing.drawingPath && <DrawingThumbnailVisibleIcon />}
              </p>
            )}
          </TableCell>
          <TableCell width={theme.var.drawingsOutputTypeCellWidth}>{drawing.outputType}</TableCell>
          <TableCell width={theme.var.drawingsSwitchCellWidth} align="right">
            <FormControlLabel
              labelPlacement="start"
              label={drawing.available ? text.outputAvailable : text.outputUnavailable}
              control={
                <Switch
                  name={drawing.drawingPath}
                  value={drawing.outputType}
                  checked={drawing.available}
                  onChange={handleToggle}
                />
              }
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default DrawingExportRows;
