import {
  DarkGreyTooltip,
  TooltipBodyTypography,
  TooltipFooterTypography,
  TooltipTitleTypography,
} from '@mid-react-common/common';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import text from 'publisher.text.json';
import { ProductDefinitionEngine, productDefinitionEngines } from 'mid-types';
import { accBridgeLink } from 'mid-utils';
import { publishTestIds } from 'tests/helpers/dataTestIds';
import { PublishErrorContainer } from './PublishingHeader.styles';

type PublishErrorIconWithTooltipProps = {
  publishingDisabledCause: string;
  publishingIncompatibleAppEngine: ProductDefinitionEngine | null;
};

export const PublishErrorIconWithTooltip: React.FC<PublishErrorIconWithTooltipProps> = ({
  publishingDisabledCause,
  publishingIncompatibleAppEngine,
}) => {
  if (
    publishingDisabledCause === text.publishingDisabledCause.authoringAppIsIncompatible &&
    publishingIncompatibleAppEngine
  ) {
    const authoringAppIncompatibleWarningMap: Record<ProductDefinitionEngine, string> = {
      [productDefinitionEngines.INVENTOR]: text.publishingDisabledCause.authoringAppIsIncompatibleWarningForInventor,
      [productDefinitionEngines.FUSION]: text.publishingDisabledCause.authoringAppIsIncompatibleWarningForFusion,
    };

    return (
      <DarkGreyTooltip
        title={
          <PublishErrorContainer>
            <TooltipTitleTypography>{publishingDisabledCause}</TooltipTitleTypography>
            <TooltipBodyTypography>
              {text.publishingDisabledCause.authoringAppIsIncompatibleBodyParagraph1}
            </TooltipBodyTypography>
            <TooltipBodyTypography>
              {authoringAppIncompatibleWarningMap[publishingIncompatibleAppEngine]}
            </TooltipBodyTypography>
            <TooltipBodyTypography>
              {text.publishingDisabledCause.authoringAppIsIncompatibleBodyParagraph3}
            </TooltipBodyTypography>
          </PublishErrorContainer>
        }
        data-testid={publishTestIds.publishWarningIcon}
        arrow
      >
        <IconButton>
          <WarningAmberIcon color="error" />
        </IconButton>
      </DarkGreyTooltip>
    );
  }

  return (
    <DarkGreyTooltip
      title={
        <PublishErrorContainer>
          <TooltipTitleTypography>{publishingDisabledCause}</TooltipTitleTypography>
          <Link
            className="mid-tooltip-link"
            underline="none"
            href={accBridgeLink}
            variant="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TooltipFooterTypography>{text.informedDesignAndACCBridge}</TooltipFooterTypography>
          </Link>
        </PublishErrorContainer>
      }
      data-testid={publishTestIds.publishWarningIcon}
      arrow
    >
      <IconButton>
        <WarningAmberIcon color="error" />
      </IconButton>
    </DarkGreyTooltip>
  );
};
