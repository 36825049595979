import { ContentWrapper, FolderTitle, Title } from './ProductNameUniquenessErrorTooltipContent.styles';
import text from '../../../publisher.text.json';
import Typography from '@mui/material/Typography';

interface ProductNameUniquenessErrorTooltipContentProps {
  productFolderTitlesBreadcrumbs: string[];
}

export const ProductNameUniquenessErrorTooltipContent: React.FC<ProductNameUniquenessErrorTooltipContentProps> = ({
  productFolderTitlesBreadcrumbs,
}) => (
  <ContentWrapper>
    <Title variant="subtitle2">{text.productNameUniquenessErrorTooltipTitle}</Title>
    {productFolderTitlesBreadcrumbs.map((folderTitle, index) => {
      // if it's the last element, don't add the slash and have a different style
      if (index === productFolderTitlesBreadcrumbs.length - 1) {
        return <span key={folderTitle + index}>{folderTitle}</span>;
      }

      return <FolderTitle key={folderTitle + index}>{folderTitle} / </FolderTitle>;
    })}
    <Typography variant="subtitle2">{text.productNameUniquenessErrorTooltipFooter}</Typography>
  </ContentWrapper>
);
