import Button from '@mui/material/Button';
import text from '../../../publisher.text.json';
import UpdateFormIcon from './icons/UpdateFormIcon';
import publisherTestIds from '../../../publisher.testids';

interface FormWorkspaceControlsProps {
  handleUpdateForm: () => void;
}

const { formWorkspaceUpdateForm } = publisherTestIds;

export const FormWorkspaceControls: React.FC<FormWorkspaceControlsProps> = ({ handleUpdateForm }) => (
  <Button startIcon={<UpdateFormIcon />} variant="text" onClick={handleUpdateForm} data-testid={formWorkspaceUpdateForm}>
    {text.blocklyUpdateForm}
  </Button>
);
