import { IconWrapper, Spacing, Text } from '@mid-react-common/common';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import testIds from 'publisher.testids';
import text from 'publisher.text.json';
import React, { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { ProductDefinitionContainer } from '../SourceContentTab.styles';
import { useProductDefinitionName } from './useProductDefinitionName';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sourceContentTestIds } from '../../../../tests/helpers/dataTestIds';
import CustomComponentsContext from 'context/Customizers/customizerComponents.context';

export const TextFieldWrapper = styled(TextField)<{ width?: number }>`
  width: ${({ width, theme }) => (width ? `${width}px` : `${theme.var.wideInputControl}px`)};
`;

interface ProductDefinitionNameProps {
  initialName: string;
}

const ProductDefinitionName: React.FC<ProductDefinitionNameProps> = ({ initialName }): JSX.Element => {
  const theme = useTheme();
  const { enableStaticContent } = useFlags();
  const { productDefinitionUIConfiguration } = useContext(CustomComponentsContext);

  const { productDefinitionAssembly, productDefinitionName } = useProductDefinitionStore(
    useShallow((state) => ({
      productDefinitionAssembly: state.assembly,
      productDefinitionName: state.name,
    })),
  );

  const {
    productDefinitionNameError,
    handleProductDefinitionNameChange,
    isProductConfigurable,
    handleProductDefinitionConfigurableToggle,
  } = useProductDefinitionName();

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {text.productDefinitionLabel}
      </Typography>
      <Text>
        <IconWrapper>
          <VisibilityOffIcon />
        </IconWrapper>
        {text.sourceContentProductDefinitionInfo}
      </Text>
      <Spacing />
      <ProductDefinitionContainer>
        {(productDefinitionName || initialName) && (
          <TextFieldWrapper
            size="small"
            error={!!productDefinitionNameError}
            helperText={productDefinitionNameError}
            width={theme.var.wideInputControl * 1.5}
            label={text.productDefinitionName}
            inputProps={{ 'data-testid': testIds.productDefinitionNameInput }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditIcon />
                </InputAdornment>
              ),
            }}
            placeholder={text.productDefinitionNameUntitled}
            disabled={!productDefinitionAssembly}
            defaultValue={productDefinitionName || initialName}
            onChange={handleProductDefinitionNameChange}
          />
        )}
        {enableStaticContent && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isProductConfigurable}
                  onChange={handleProductDefinitionConfigurableToggle}
                  data-testid={sourceContentTestIds.configurableButton}
                  disabled={!!productDefinitionUIConfiguration.isConfigurableDisabled}
                />
              }
              label={text.productDefinitionConfigurableLabel}
            />
          </FormGroup>
        )}
      </ProductDefinitionContainer>
    </>
  );
};

export default ProductDefinitionName;
