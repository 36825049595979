import '@blockly/block-plus-minus';
import { WorkspaceSearch } from '@blockly/plugin-workspace-search';
import { Backpack } from '@blockly/workspace-backpack';
import { ZoomToFitControl } from '@blockly/zoom-to-fit';
import { useTheme } from '@mui/material/styles';
import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import text from 'publisher.text.json';
import { ProductDefinitionInputParameter, SerializedBlocklyWorkspaceState } from 'mid-addin-lib';
import { NOTIFICATION_STATUSES, NotificationContext } from '@mid-react-common/common';
import { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { initializeBlocklyExtensions } from './InputCodeblocks.extensions';
import './InputCodeblocks.blocks';
import { initializeBlocklyMutators } from './InputCodeblocks.mutators';
import { initializeDuplicateConnectedPlugin } from '../Plugins/DuplicateConnectedPlugin';
import blocklyConfig, { darkTheme, lightTheme } from '../blocklyConfig';
import { BLOCKLY_EVENTS_TO_UPDATE, DATA_STORE_DEBOUNCE_TIME, muiDarkPaletteMode } from '../constants';
import inputsWorkspaceToolbox from './InputCodeblocks.toolbox';
import useTransferBlocks from './transferBlocks/useTransferBlocks';
import { getBlocksExtraState, handleWorkspaceSwitch } from '../utils';
import { Abstract } from 'blockly/core/events/events_abstract';
import { debounce, isUndefined } from 'lodash';
import useGenerateBlocklyState from './generateBlocklyState/useGenerateBlocklyState';
import { inputWorkspaceCustomBlocks } from './InputCodeblocks.blocks';
import { BACKPACK_CHANGE, blocklyToolboxInputsCategory, currentRuleKey } from './InputCodeblocks.constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

//Initialize custom blocks definition
Blockly.defineBlocksWithJsonArray(inputWorkspaceCustomBlocks);

interface useInputCodeblocksProps {
  inputs: ProductDefinitionInputParameter[];
  backpackContents: string[];
  productDefinitionName: string;
  productDefinitionTopLevelFolder: string;
  initialState?: SerializedBlocklyWorkspaceState;
  hidden?: boolean;
  setInputsCodeBlocksRules: (payload: { key: string; code: string }) => void;
  setInputsCodeBlocksWorkspace: (payload: SerializedBlocklyWorkspaceState) => void;
  setBackpackContents: (backpackContents: string[]) => void;
  setUpdateFormEnabled: (enabled: boolean) => void;
  highlightedBlockId?: string;
  setAreInputRulesLoaded: (loaded: boolean) => void;
  getCodeRef: MutableRefObject<(() => string) | undefined>;
}

interface useInputCodeblocksReturn {
  ref: React.RefObject<HTMLDivElement>;
}

const useInputCodeblocks = ({
  initialState,
  hidden,
  inputs,
  backpackContents,
  productDefinitionName,
  productDefinitionTopLevelFolder,
  highlightedBlockId,
  setInputsCodeBlocksWorkspace,
  setInputsCodeBlocksRules,
  setBackpackContents,
  setUpdateFormEnabled,
  setAreInputRulesLoaded,
  getCodeRef,
}: useInputCodeblocksProps): useInputCodeblocksReturn => {
  const { enableApplicableParameter } = useFlags();

  const theme = useTheme();
  const inputsWorkspaceDiv = useRef<HTMLDivElement>(null);

  // We only need the initial list of inputs to initialize the workspace.
  // We don't want to reinitialize the workspace when the inputs change.
  // Hence, we only reference the inputs
  const inputsRef = useRef<ProductDefinitionInputParameter[]>(inputs);

  const autogeneratedInitialState = useGenerateBlocklyState(inputsRef.current);

  const backpackRef = useRef<Backpack>();

  const workspace = useRef<Blockly.WorkspaceSvg>();
  const [isBlocklyWorkspaceInitialized, setBlocklyWorkspaceInitialized] = useState(false);
  const [initialBlocksLoaded, setInitialBlocksLoaded] = useState(false);
  const [isTransferBlocksInitialized, setIsTransferBlocksInitialized] = useState(false);

  const { showNotification } = useContext(NotificationContext);

  const { initialize: initializeTransferBlocks } = useTransferBlocks({
    blocklyRef: workspace,
    productDefinitionName,
    productDefinitionTopLevelFolder,
  });

  const [arePluginsInitialized, setArePluginsInitialized] = useState(false);

  const isBlocksStateValid = useCallback(() => {
    const extraStateList = getBlocksExtraState(getState());
    return extraStateList.every((extraState) => {
      if (!extraState.inputsDropdown) {
        return true;
      }
      return inputsRef.current.some((input) => {
        if (extraState.inputsDropdown) {
          return JSON.parse(extraState.inputsDropdown).name === input.name;
        }
      });
    });
  }, []);

  const handleBlockStateValidation = useCallback(() => {
    if (!isBlocksStateValid()) {
      showNotification({
        message: text.blocklyInputNotAdopted,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [isBlocksStateValid, showNotification]);

  const saveRulesAndWorkspace = useMemo(
    () =>
      debounce((workspace: Blockly.WorkspaceSvg) => {
        if (workspace.isDragging()) {
          return;
        }

        // Save rule in dataStore
        setInputsCodeBlocksRules({ key: currentRuleKey, code: getCode() });
        // Save Blockly Workspace state in dataStore
        setInputsCodeBlocksWorkspace(getState());
      }, DATA_STORE_DEBOUNCE_TIME),
    [setInputsCodeBlocksRules, setInputsCodeBlocksWorkspace],
  );
  // We need to keep a reference to the debounced function to cancel it when the component unmounts
  const saveWorkspaceRef = useRef(saveRulesAndWorkspace);

  //Update Data store
  const handleUpdateDataStore = useCallback(
    (event: Abstract): void => {
      if (!workspace.current) {
        return;
      }

      if (event.type === Blockly.Events.FINISHED_LOADING) {
        // Validate block state
        handleBlockStateValidation();

        // Set initial preview for rules once workspace is done loading
        setInputsCodeBlocksRules({ key: currentRuleKey, code: getCode() });
        // Save Blockly Workspace state in dataStore
        setInputsCodeBlocksWorkspace(getState());
        setAreInputRulesLoaded(true);

        // Empty trashcan - React strict loads twice, it deletes the blocks and then loads them again
        workspace.current.trashcan?.emptyContents();
      }

      if (BLOCKLY_EVENTS_TO_UPDATE.includes(event.type)) {
        saveRulesAndWorkspace(workspace.current);
      }

      // Update backpack
      if (event.type === BACKPACK_CHANGE && backpackRef.current) {
        setBackpackContents(backpackRef.current.getContents());
      }

      setUpdateFormEnabled(true);
    },
    [
      setUpdateFormEnabled,
      handleBlockStateValidation,
      setInputsCodeBlocksRules,
      setInputsCodeBlocksWorkspace,
      setAreInputRulesLoaded,
      saveRulesAndWorkspace,
      setBackpackContents,
    ],
  );

  useEffect(() => {
    if (workspace.current && !isTransferBlocksInitialized) {
      initializeTransferBlocks(workspace.current);
      setIsTransferBlocksInitialized(true);
    }
  }, [initializeTransferBlocks, isTransferBlocksInitialized]);

  // Initialize plugins
  useEffect(() => {
    if (workspace.current && !arePluginsInitialized && isBlocklyWorkspaceInitialized) {
      initializeDuplicateConnectedPlugin();

      const zoomToFit = new ZoomToFitControl(workspace.current);
      zoomToFit.init();

      const workspaceSearch = new WorkspaceSearch(workspace.current);
      workspaceSearch.init();

      const newBackpack = new Backpack(workspace.current);
      newBackpack.init();
      backpackRef.current = newBackpack;

      setArePluginsInitialized(true);
    }
  }, [arePluginsInitialized, isBlocklyWorkspaceInitialized]);

  // Initialize Blockly Workspace
  useEffect(() => {
    const cancelDebounceBeforeUnmount = saveWorkspaceRef.current;
    if (inputsWorkspaceDiv.current) {
      //Initialize custom mutators and extensions
      initializeBlocklyMutators(enableApplicableParameter);
      initializeBlocklyExtensions(inputsRef.current, enableApplicableParameter);

      workspace.current = Blockly.inject(inputsWorkspaceDiv.current, {
        ...blocklyConfig,
        toolbox: inputsWorkspaceToolbox,
      });

      // We dynamically register the blocks in the toolbox since the inputs can change
      workspace.current.registerToolboxCategoryCallback(blocklyToolboxInputsCategory, () =>
        inputWorkspaceCustomBlocks.map((block) => ({ kind: 'block', type: block.type })),
      );

      // Initialize Blockly Workspace and its Mutators, Extensions adn Events just once
      setBlocklyWorkspaceInitialized(true);
      return () => {
        //Clean up Blockly
        if (workspace.current) {
          cancelDebounceBeforeUnmount.cancel();
          workspace.current.dispose();
          setInitialBlocksLoaded(false);
        }
      };
    }
  }, [enableApplicableParameter]);

  // Load initial state
  useEffect(() => {
    // This side effect should run once Blockly workspace has been initialized
    if (isBlocklyWorkspaceInitialized && !initialBlocksLoaded && workspace.current) {
      try {
        Blockly.serialization.workspaces.load(initialState || autogeneratedInitialState, workspace.current);
        if (isUndefined(initialState)) {
          workspace.current.cleanUp();
        }
        if (backpackRef.current) {
          backpackRef.current.setContents(backpackContents);
        }

        //Once the blocks are loaded, add update data store listener
        workspace.current?.addChangeListener(handleUpdateDataStore);
        setUpdateFormEnabled(true);
      } catch (e) {
        // If initial state is invalid, start with an empty state
        showNotification({
          message: text.blocklyInvalidInitialState,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } finally {
        setInitialBlocksLoaded(true);
      }
    }
  }, [
    autogeneratedInitialState,
    backpackContents,
    handleUpdateDataStore,
    initialBlocksLoaded,
    initialState,
    isBlocklyWorkspaceInitialized,
    setUpdateFormEnabled,
    showNotification,
  ]);

  // function to return current blockly state
  const getState = () => {
    if (workspace.current) {
      return Blockly.serialization.workspaces.save(workspace.current);
    }
    return {};
  };

  // for e2e tests
  window.setBlocklyState = (state: SerializedBlocklyWorkspaceState) => {
    if (workspace.current) {
      Blockly.serialization.workspaces.load(state, workspace.current);
    }
  };

  // function to return current blockly code
  const getCode = (highlightBlocks?: boolean) => {
    if (highlightBlocks) {
      javascriptGenerator.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
    } else {
      javascriptGenerator.STATEMENT_PREFIX = '';
    }
    return javascriptGenerator.workspaceToCode(workspace.current);
  };

  // Theme
  useEffect(() => {
    if (theme.palette.mode === muiDarkPaletteMode) {
      workspace.current?.setTheme(darkTheme);
    } else {
      workspace.current?.setTheme(lightTheme);
    }
  }, [theme.palette.mode]);

  // Re-render workspace when shown
  useEffect(() => {
    handleWorkspaceSwitch(workspace.current, hidden);
  }, [hidden]);

  // Highlight block
  useEffect(() => {
    if (workspace.current && highlightedBlockId) {
      workspace.current?.highlightBlock(highlightedBlockId);
    } else {
      workspace.current?.highlightBlock(null);
    }
  }, [highlightedBlockId]);

  getCodeRef.current = () => getCode(true);

  return {
    ref: inputsWorkspaceDiv,
  };
};
export default useInputCodeblocks;
