import { ProductDefinition } from 'mid-addin-lib';
import { useRef, useEffect } from 'react';
import { useProductDefinitionStore } from './productDefinitionStore';
import deepEqual from 'deep-equal';
import { publisherStoreActions } from './PublisherDataStore';
import { useShallow } from 'zustand/react/shallow';
import { cloneDeep } from 'lodash';
import { productDefinitionWithoutData } from './initialProductDefinition';

export interface SourceContentInitialData {
  name?: string;
  assembly?: string;
  thumbnail?: string;
}

const getUpdatedInitialValueOfProductDefinitionOnEdit = (
  initialValueOfProductDefinitionOnEdit: ProductDefinition,
  currentProductDefinition: ProductDefinition,
): ProductDefinition => {
  let updatedProductDefinition = cloneDeep(initialValueOfProductDefinitionOnEdit);
  // If the user saves an existing product
  // definition or publishes it, set unsaved changes = false
  if (initialValueOfProductDefinitionOnEdit?.lastUpdated !== currentProductDefinition.lastUpdated) {
    updatedProductDefinition = currentProductDefinition;
  }

  // The thumbnail can take time to load, so we include
  // it in the initial value once it is available
  if (!initialValueOfProductDefinitionOnEdit.thumbnail && currentProductDefinition.thumbnail) {
    updatedProductDefinition.thumbnail = currentProductDefinition.thumbnail;
  }

  // Drawings load asynchronously, so we have to update
  // the initial value
  if (
    initialValueOfProductDefinitionOnEdit.drawingThumbnails?.length !== currentProductDefinition.drawingThumbnails?.length
  ) {
    updatedProductDefinition.drawingThumbnails = currentProductDefinition.drawingThumbnails;
  }
  // Update the Initial parameter defaults when the
  // parameters tab is loaded for the first time
  if (
    initialValueOfProductDefinitionOnEdit.parametersDefaults?.length !== currentProductDefinition.parametersDefaults?.length
  ) {
    updatedProductDefinition.parametersDefaults = currentProductDefinition.parametersDefaults;
  }
  // Update the Initial prod definition when then
  // rules are loaded for the first time
  if (initialValueOfProductDefinitionOnEdit.rules?.length !== currentProductDefinition.rules?.length) {
    updatedProductDefinition.rules = currentProductDefinition.rules;
  }
  // Update the Initial prod definition when then
  // Form codeblocks workspace is loaded for the first time
  if (!initialValueOfProductDefinitionOnEdit.formCodeBlocksWorkspace && !!currentProductDefinition.formCodeBlocksWorkspace) {
    updatedProductDefinition.formCodeBlocksWorkspace = currentProductDefinition.formCodeBlocksWorkspace;
  }
  // Update the Initial prod definition when then
  // Inputs codeblocks workspace is loaded for the first time
  if (!initialValueOfProductDefinitionOnEdit.codeBlocksWorkspace && !!currentProductDefinition.codeBlocksWorkspace) {
    updatedProductDefinition.codeBlocksWorkspace = currentProductDefinition.codeBlocksWorkspace;
  }

  // Update the Initial prod definition when then
  // outputs tab initial data is loaded for the first time
  if (!initialValueOfProductDefinitionOnEdit.outputs.length && !!currentProductDefinition.outputs.length) {
    updatedProductDefinition.outputs = currentProductDefinition.outputs;
  }

  return updatedProductDefinition;
};

export const useUnsavedProductDefinitionChangesTracker = (): void => {
  const currentProductDefinition = useProductDefinitionStore(useShallow((state) => state));
  const initialValueOfProductDefinitionOnCreate = useRef<SourceContentInitialData | undefined>(undefined);
  const initialValueOfProductDefinitionOnEdit = useRef<ProductDefinition | undefined>(undefined);
  const { name, assembly, thumbnail, id } = currentProductDefinition;
  const isSavedProductDefinition = !!id && !deepEqual(id, productDefinitionWithoutData.id);

  // When the current product definition has
  // no data (product selection screen) clear
  // all state/refs
  useEffect(() => {
    const currentProductDefinitionHasNoData = deepEqual(currentProductDefinition, productDefinitionWithoutData, {
      strict: true,
    });
    if (currentProductDefinitionHasNoData) {
      // Clear all unsaved changes related data
      initialValueOfProductDefinitionOnCreate.current = undefined;
      initialValueOfProductDefinitionOnEdit.current = undefined;
      publisherStoreActions.setProductDefinitionHasUnsavedChanges(false);
    }
  }, [currentProductDefinition]);

  // "Create Product Definition" workflow: Check for unsaved changes
  useEffect(() => {
    if (isSavedProductDefinition) {
      return;
    }
    // Store the *initial* values of product
    // definitions for the "create" workflow.
    const storeInitialValueOfProduceDefinitionOnCreate: boolean = !!(
      assembly &&
      name &&
      thumbnail &&
      !initialValueOfProductDefinitionOnCreate.current
    );

    if (storeInitialValueOfProduceDefinitionOnCreate) {
      // When the source content page loads, we auto select/load
      // some data. When this data is set in the app it should not be
      // considered as unsaved changes, so we consider it the initial state.
      initialValueOfProductDefinitionOnCreate.current = { assembly, name, thumbnail };
      publisherStoreActions.setProductDefinitionHasUnsavedChanges(false);
      return;
    }

    if (initialValueOfProductDefinitionOnCreate.current) {
      // When the source content page loads, we auto select/load
      // some data which should not be considered unsaved changes,
      // so we override those values in the data being compared to
      // the currentProductDefinition.
      // stringify to only compare object data, not react metadata
      const hasUnsavedChanges = !deepEqual(
        currentProductDefinition,
        {
          ...productDefinitionWithoutData,
          ...initialValueOfProductDefinitionOnCreate.current,
        },
        { strict: true },
      );
      publisherStoreActions.setProductDefinitionHasUnsavedChanges(hasUnsavedChanges);
    }
  }, [assembly, currentProductDefinition, isSavedProductDefinition, name, thumbnail]);

  // "Edit Product Definition" workflow: Check for unsaved changes
  useEffect(() => {
    if (!isSavedProductDefinition) {
      return;
    }
    // Store the *initial* values of product
    // definitions for the "edit" workflow.
    const storeInitialValueOfProduceDefinitionOnEdit: boolean = !initialValueOfProductDefinitionOnEdit.current;

    if (storeInitialValueOfProduceDefinitionOnEdit) {
      initialValueOfProductDefinitionOnEdit.current = currentProductDefinition;
      publisherStoreActions.setProductDefinitionHasUnsavedChanges(false);
      return;
    }

    if (initialValueOfProductDefinitionOnEdit.current) {
      const updatedInitialValue = getUpdatedInitialValueOfProductDefinitionOnEdit(
        initialValueOfProductDefinitionOnEdit.current,
        currentProductDefinition,
      );
      // make sure to update the ref
      initialValueOfProductDefinitionOnEdit.current = updatedInitialValue;
      // 1. We set `lastUpdated=0`, as this fields is updated on every
      // "save" & this will cause unsavedChanges=true.
      // even though all other fields are the same
      // 2. lodash.isEqual does not support deep comparison of leaf nodes
      const initialObjectForEqualityCheck: ProductDefinition = {
        ...updatedInitialValue,
        lastUpdated: 0,
      };
      const currentObjectForEqualityCheck: ProductDefinition = {
        ...currentProductDefinition,
        lastUpdated: 0,
      };
      const hasUnsavedChanges = !deepEqual(initialObjectForEqualityCheck, currentObjectForEqualityCheck);
      publisherStoreActions.setProductDefinitionHasUnsavedChanges(hasUnsavedChanges);
    }
  }, [currentProductDefinition, isSavedProductDefinition]);
};
