import { blocklyKinds } from '../constants';
import text from 'publisher.text.json';
import { blocklyToolboxInputsCategory } from './InputCodeblocks.constants';

// Default Sample Toolbox
const inputsWorkspaceToolbox = {
  kind: blocklyKinds.CATEGORY_TOOLBOX,
  contents: [
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxLogicLabel,
      contents: [
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_if',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_if',
          extraState: {
            hasElse: true,
          },
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'logic_compare',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'logic_operation',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'logic_negate',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'logic_boolean',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'logic_ternary',
        },
      ],
    },
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxLoopsLabel,
      contents: [
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_repeat_ext',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_repeat',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_whileUntil',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_for',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_forEach',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'controls_flow_statements',
        },
      ],
    },
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxMathLabel,
      contents: [
        {
          kind: blocklyKinds.BLOCK,
          type: 'math_number',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'math_arithmetic',
        },

        {
          kind: blocklyKinds.BLOCK,
          type: 'math_single',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'math_trig',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'math_round',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'math_modulo',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'math_constrain',
        },
      ],
    },
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxTextLabel,
      contents: [
        {
          kind: blocklyKinds.BLOCK,
          type: 'text',
        },
        { kind: blocklyKinds.BLOCK, type: 'text_join' },
        { kind: blocklyKinds.BLOCK, type: 'text_append' },
        { kind: blocklyKinds.BLOCK, type: 'text_length' },
        { kind: blocklyKinds.BLOCK, type: 'text_isEmpty' },
        { kind: blocklyKinds.BLOCK, type: 'text_indexOf' },
        { kind: blocklyKinds.BLOCK, type: 'text_charAt' },
        { kind: blocklyKinds.BLOCK, type: 'text_getSubstring' },
        { kind: blocklyKinds.BLOCK, type: 'text_changeCase' },
        { kind: blocklyKinds.BLOCK, type: 'text_trim' },
        { kind: blocklyKinds.BLOCK, type: 'text_replace' },
        { kind: blocklyKinds.BLOCK, type: 'text_count' },
        { kind: blocklyKinds.BLOCK, type: 'text_reverse' },
        { kind: blocklyKinds.BLOCK, type: 'text_print' },
      ],
    },
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxListsLabel,
      contents: [
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_create_with',
        },

        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_repeat',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_length',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_isEmpty',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_indexOf',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_getIndex',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_setIndex',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_getSublist',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_split',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_sort',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_reverse',
        },
        {
          kind: blocklyKinds.BLOCK,
          type: 'lists_isEmpty',
        },
      ],
    },
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxVariablesLabel,
      custom: 'VARIABLE',
    },
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxFunctionsLabel,
      custom: 'PROCEDURE',
    },
    {
      kind: blocklyKinds.CATEGORY,
      name: text.blocklyToolboxParametersLabel,
      custom: blocklyToolboxInputsCategory,
    },
  ],
};

export default inputsWorkspaceToolbox;
