import { useLogAndShowNotification } from '@mid-react-common/common';
import { checkAndGenerateThumbnailInBase64, createFullPath, getThumbnailImgPath } from 'mid-addin-lib';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { productDefinitionActions, useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { useShallow } from 'zustand/react/shallow';
import text from '../../../publisher.text.json';

interface UseThumbnailReturn {
  isThumbnailLoading: boolean;
  thumbnailInBase64: { name: string; base64: string } | undefined;
  handleRefreshThumbnail: () => void;
}

const GET_THUMBNAIL_PATH_KEY = 'getThumbnailPath';
const CHECK_AND_GENERATE_THUMBNAIL_IN_BASE64_KEY = 'checkAndGenerateThumbnailInBase64';

const useThumbnail = (): UseThumbnailReturn => {
  const [localFullIamPath, setLocalFullIamPath] = useState<string>('');
  const queryClient = useQueryClient();
  const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(true);

  const { thumbnail, assembly, topLevelFolder } = useProductDefinitionStore(
    useShallow((state) => ({
      thumbnail: state.thumbnail,
      assembly: state.assembly,
      topLevelFolder: state.topLevelFolder,
    })),
  );

  // Only Fetch the thumbnail path if it is not already set in the product definition or on manual refetch
  // We do not want to dirty the document if the thumbnail is already set
  // Everytimg this hostApi function is called, it interacts with the document and causes unsaved changes to the document
  const {
    data: thumbnailPath,
    error: thumbnailPathError,
    refetch: refetchThumbnailPath,
    isLoading: isThumbnailPathLoading,
    isFetching: isThumbnailPathFetching,
  } = useQuery({
    queryKey: [GET_THUMBNAIL_PATH_KEY],
    queryFn: () => getThumbnailImgPath(localFullIamPath),
    enabled: !thumbnail && !!localFullIamPath,
  });
  useLogAndShowNotification(thumbnailPathError, text.notificationThumbnailFailed);

  useEffect(() => {
    if (thumbnailPath) {
      productDefinitionActions.setThumbnail(thumbnailPath);
    }
  }, [thumbnailPath, isThumbnailPathFetching]);

  useEffect(() => {
    if (assembly && !localFullIamPath) {
      // If top level is set, then assembly will be relative to top level
      // Otherwise, assembly will be an absolute path
      const fullPath = topLevelFolder ? createFullPath(topLevelFolder, assembly) : assembly;
      setLocalFullIamPath(fullPath);
    }
  }, [topLevelFolder, assembly, localFullIamPath]);

  // This function will only generate a new thumbnail if the thumbnail has change
  // If not, it will return the existing thumbnail
  const {
    data: thumbnailInBase64,
    error: thumbnailError,
    isLoading: isBase64Loading,
  } = useQuery({
    queryKey: [CHECK_AND_GENERATE_THUMBNAIL_IN_BASE64_KEY],
    queryFn: () => checkAndGenerateThumbnailInBase64(thumbnail, localFullIamPath),
    enabled: !!thumbnail && !!localFullIamPath,
  });
  useLogAndShowNotification(thumbnailError, text.notificationThumbnailFailed);

  const handleRefreshThumbnail = () => {
    productDefinitionActions.setThumbnail('');
    queryClient.invalidateQueries({ queryKey: [GET_THUMBNAIL_PATH_KEY, CHECK_AND_GENERATE_THUMBNAIL_IN_BASE64_KEY] });
    refetchThumbnailPath();
  };

  useEffect(() => {
    setIsThumbnailLoading(isThumbnailPathLoading || isBase64Loading);
  }, [isThumbnailPathLoading, isBase64Loading]);

  return {
    isThumbnailLoading,
    thumbnailInBase64,
    handleRefreshThumbnail,
  };
};

export default useThumbnail;
