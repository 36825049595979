import { ContextMenuRegistry, serialization } from 'blockly';
import text from 'publisher.text.json';
import { CONTEXT_MENU_DUPLICATE_CONNECTED, CONTEXT_MENU_ENABLED, CONTEXT_MENU_HIDDEN } from '../constants';

export const initializeDuplicateConnectedPlugin = (): void => {
  /*
   * Context Menu Override
   */

  const ContextMenuDuplicate = {
    displayText: text.blocklyDuplicate,
    preconditionFn: (scope: ContextMenuRegistry.Scope) => {
      if (
        scope.block &&
        !scope.block.isInFlyout &&
        scope.block.isDuplicatable() &&
        scope.block.isDeletable() &&
        scope.block.workspace.options.plugins[CONTEXT_MENU_DUPLICATE_CONNECTED]
      ) {
        return CONTEXT_MENU_ENABLED;
      }
      return CONTEXT_MENU_HIDDEN;
    },
    callback: (scope: ContextMenuRegistry.Scope) => {
      //We override the behavior to include the attached blocks ("next" property in block) instead of only the selected
      if (!scope.block) {
        return;
      }
      const blockWithNext = serialization.blocks.save(scope.block, {
        addCoordinates: true,
        addNextBlocks: true,
      });

      scope.block.workspace.paste(blockWithNext);
    },
    scopeType: ContextMenuRegistry.ScopeType.BLOCK,
    id: CONTEXT_MENU_DUPLICATE_CONNECTED,
    weight: 1,
  };

  // Remove the default duplicate ContextMenu option and replace it with ours
  const duplicateId = ContextMenuRegistry.registry.getItem(CONTEXT_MENU_DUPLICATE_CONNECTED);
  if (duplicateId !== null) {
    ContextMenuRegistry.registry.unregister(CONTEXT_MENU_DUPLICATE_CONNECTED);
  }
  ContextMenuRegistry.registry.register(ContextMenuDuplicate);
};
