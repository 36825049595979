import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { validateProductDefinitionName } from '../../../../utils/productDefinition';
import { productDefinitionActions, useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { publisherStoreActions } from '../../../../context/DataStore/PublisherDataStore';
import { useShallow } from 'zustand/react/shallow';
import { CodeblocksWorkspaceType } from 'components/Rules/types';
import { formRulesKey } from 'components/Rules/FormCodeblocks/FormCodeblocks.constants';
import { getProductConfigurableStatus } from 'mid-utils';
import { getInputsWithDefaultParameters } from 'components/Rules/utils';

export const productDefinitionNameSaveDebounceTimeout = 500;

type UseProductDefinitionNameState = {
  productDefinitionNameError: string | null;
  handleProductDefinitionNameChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  isProductConfigurable: boolean;
  handleProductDefinitionConfigurableToggle: (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const useProductDefinitionName = (): UseProductDefinitionNameState => {
  const { productDefinitionName, productDefinitionId, isConfigurable, inputs, parameterDefaults } =
    useProductDefinitionStore(
      useShallow((state) => ({
        productDefinitionName: state.name,
        productDefinitionId: state.id,
        isConfigurable: state.isConfigurable,
        inputs: state.inputs,
        parameterDefaults: state.parametersDefaults,
      })),
    );

  const [localProductDefinitionName, setLocalProductDefinitionName] = useState<string | undefined>(undefined);
  const [productDefinitionNameError, setProductDefinitionNameError] = useState<string | null>(null);

  const debouncedValidation = debounce((newName: string) => {
    setLocalProductDefinitionName(newName);
    productDefinitionActions.setName(newName);
  }, productDefinitionNameSaveDebounceTimeout);

  const handleProductDefinitionNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    debouncedValidation(newName);
  };

  const handleProductDefinitionConfigurableToggle = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked && inputs.length === 0) {
        productDefinitionActions.removeRule(formRulesKey);
        productDefinitionActions.setFormCodeBlocksWorkspace(undefined);
      }

      if (!checked) {
        // Reset workspace back to form for static content
        publisherStoreActions.setLatestWorkspaceSelected(CodeblocksWorkspaceType.FORM);

        // Reset inputs back to default values for static content
        const updatedInputsWithDefaults = getInputsWithDefaultParameters({
          parameterDefaults,
          inputs,
          resetOptionalAttributes: true,
        });
        productDefinitionActions.replaceAllInputs(updatedInputsWithDefaults);
      } else if (inputs.length) {
        // All optional fields are reset previously when changing inputs from configurable to static content.
        // When we changed back from static to configurable, we need to notify user to use workspace to run the existing input rules
        // to add back optional fields.
        publisherStoreActions.setRecentlyAdoptedInputs(inputs);
      }
      productDefinitionActions.setConfigurable(checked);
    },
    [inputs, parameterDefaults],
  );

  useEffect(() => {
    if (productDefinitionName) {
      setLocalProductDefinitionName(productDefinitionName);
    }
  }, [productDefinitionName]);

  useEffect(() => {
    async function validateName(newName: string) {
      const validationError = await validateProductDefinitionName(newName, productDefinitionId);
      setProductDefinitionNameError(validationError.cause);
      publisherStoreActions.setIsErronousProductDefinitionName({
        error: validationError.error,
        cause: validationError.cause,
      });
    }
    if (localProductDefinitionName !== undefined) {
      validateName(localProductDefinitionName);
    }
  }, [productDefinitionId, localProductDefinitionName]);

  return {
    productDefinitionNameError,
    handleProductDefinitionNameChange,
    isProductConfigurable: getProductConfigurableStatus(isConfigurable),
    handleProductDefinitionConfigurableToggle,
  };
};
