import { DividerWrapper } from '@mid-react-common/common';
import React, { useContext } from 'react';
import text from '../../../publisher.text.json';
import { OutputProps } from './Output.types';
import { OutputsWrapper } from './OutputsTab.styles';
import RevitClassificationSection from './RevitClassificationSection';
import Drawing2DOutput from './Drawing2D/Drawing2DOutput';
import Output from './Output';
import { GroupedOutputs } from './GroupedOutputs/GroupedOutputs';
import { useGetAvailableRepresentations } from './hooks/useGetAvailableRepresentations';
import { useRevitClassification } from './hooks/useRevitClassification';
import { useSelectOutputRepresentations } from './hooks/useSelectOutputRepresentations';
import { useLegacyRevitCategoryAndFamilySelector } from './hooks/LegacyUseRevitClassification/useLegacyRevitCategoryAndFamilySelector';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LegacyRevitCategoryAndFamilySelector from './LegacyRevitCategoryAndFamilySelector';
import CustomComponentsContext from 'context/Customizers/customizerComponents.context';
import { OutputType } from '@adsk/offsite-dc-sdk';

const OutputsTab: React.FC = (): JSX.Element => {
  const { outputsConfiguration } = useContext(CustomComponentsContext);
  const { availableRepresentations } = useGetAvailableRepresentations();
  const { selectedOutputRepresentationsMap, handleRepresentationChange } = useSelectOutputRepresentations();

  const { enforceRevitClassification } = useFlags();
  const { isLoading, revitClassification } = useRevitClassification();
  const { revitFamilyCategory, localRevitFamily, revitFamilyCategoryList, handleOnSelectCategory, handleRevitFamilyChange } =
    useLegacyRevitCategoryAndFamilySelector();

  const RFA: OutputProps = {
    outputType: OutputType.RFA,
    title: text.outputsRevitFamily,
    acronyms: [text.outputsRFA],
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.RFA) || [],
    handleRepresentationChange,
    isRequired: true,
    children: (
      <>
        {enforceRevitClassification ? (
          <RevitClassificationSection isLoading={isLoading} revitClassification={revitClassification} />
        ) : (
          <LegacyRevitCategoryAndFamilySelector
            revitFamilyCategoryList={revitFamilyCategoryList}
            selectedCategory={revitFamilyCategory}
            revitFamily={localRevitFamily}
            handleOnSelectCategory={handleOnSelectCategory}
            handleRevitFamilyChange={handleRevitFamilyChange}
          />
        )}
      </>
    ),
    ...outputsConfiguration.get(OutputType.RFA),
  };

  const BOM: OutputProps = {
    outputType: OutputType.BOM,
    title: text.outputsBillOfMaterials,
    acronyms: [text.outputsCSV],
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.BOM) || [],
    handleRepresentationChange,
    ...outputsConfiguration.get(OutputType.BOM),
  };

  const IAM: OutputProps = {
    outputType: OutputType.IAM,
    title: text.outputsInventorModel,
    acronyms: [text.outputsIAM],
    representations: [text.outputAllRepresentations],
    selectedRepresentations: selectedOutputRepresentationsMap.has(OutputType.IAM) ? [text.outputAllRepresentations] : [],
    showExcludeIntellectualProperty: true,
    handleRepresentationChange,
    tooltipMessage: text.outputsIAMTooltipMessage,
    ...outputsConfiguration.get(OutputType.IAM),
  };

  const SAT: OutputProps = {
    outputType: OutputType.SAT,
    acronyms: [text.outputsSAT],
    fileType: text.outputsSATFileType,
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.SAT) || [],
    handleRepresentationChange,
    ...outputsConfiguration.get(OutputType.SAT),
  };

  const STEP: OutputProps = {
    outputType: OutputType.STEP,
    acronyms: [text.outputsSTEP],
    fileType: text.outputsSTEPFileType,
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.STEP) || [],
    handleRepresentationChange,
    ...outputsConfiguration.get(OutputType.STEP),
  };

  const GLB: OutputProps = {
    outputType: OutputType.GLB,
    acronyms: [text.outputsGLB],
    fileType: text.outputsGLBFileType,
    representations: availableRepresentations,
    selectedRepresentations: selectedOutputRepresentationsMap.get(OutputType.GLB) || [],
    handleRepresentationChange,
    ...outputsConfiguration.get(OutputType.GLB),
  };

  const groupedNeutralOutputs: OutputProps[] = [SAT, STEP, GLB];
  const individualOutputs: Map<string, OutputProps> = new Map([
    [text.outputsRevitFamily, RFA],
    [text.outputsBillOfMaterials, BOM],
  ]);
  if (outputsConfiguration.has(OutputType.IAM)) {
    individualOutputs.set(text.outputsInventorModel, IAM);
  }

  const outputsSectionOrderTitles: string[] = [
    text.outputsRevitFamily,
    text.outputsInventorModel,
    text.outputsDrawing2D,
    text.outputsBillOfMaterials,
    text.outputsNeutralFormat,
  ];

  return (
    <OutputsWrapper>
      {outputsSectionOrderTitles.map((outputSectionTitle, index) => {
        if (outputSectionTitle === text.outputsDrawing2D) {
          return (
            <div key={`${text.outputsDrawing2D}-${index}`}>
              <Drawing2DOutput />
              <DividerWrapper />
            </div>
          );
        }

        if (outputSectionTitle === text.outputsNeutralFormat) {
          return (
            <GroupedOutputs
              key={`${text.outputsNeutralFormat}-${index}`}
              title={text.outputsNeutralFormat}
              outputs={groupedNeutralOutputs}
            />
          );
        }

        const output = individualOutputs.get(outputSectionTitle);
        if (!output) {
          return null;
        }

        return (
          <div key={output.title}>
            <Output
              outputType={output.outputType}
              title={output.title}
              acronyms={output.acronyms}
              representations={output.representations}
              selectedRepresentations={output.selectedRepresentations}
              handleRepresentationChange={output.handleRepresentationChange}
              disabled={output.disabled}
              isRequired={output.isRequired}
              {...(output.showExcludeIntellectualProperty && {
                showExcludeIntellectualProperty: output.showExcludeIntellectualProperty,
              })}
              {...(output.tooltipMessage && { tooltipMessage: output.tooltipMessage })}
            >
              {output.children}
            </Output>
            {index !== individualOutputs.size - 1 && <DividerWrapper />}
          </div>
        );
      })}
    </OutputsWrapper>
  );
};

export default OutputsTab;
