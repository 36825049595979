import { FC, useState } from 'react';
import { StaticContentParameterAdoptionText } from '../Rules.styles';
import { AlertWrapper } from '../Rules.styles';
import text from 'publisher.text.json';

export const StaticContentParameterAdoption: FC = () => {
  const [showMessage, setShowMessage] = useState(true);

  const closeButtonOnClick = () => setShowMessage(false);

  if (showMessage) {
    return (
      <AlertWrapper severity="info" onClose={closeButtonOnClick}>
        <StaticContentParameterAdoptionText>{text.staticContent.adoptParametersInfo}</StaticContentParameterAdoptionText>
      </AlertWrapper>
    );
  }

  return null;
};
