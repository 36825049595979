import {
  checkAndGenerateThumbnailInBase64,
  getDrawingFiles,
  getThumbnailImgPath,
  ProductDefinitionOutput,
} from 'mid-addin-lib';
import { DrawingThumbnail } from 'mid-types';
import { NotificationContext } from '@mid-react-common/common';
import { isFulfilled, isRejected } from 'mid-utils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import text from '../../../../publisher.text.json';
import { GridSelectionModel } from '@mui/x-data-grid/models';
import { extractRelativeFilePath } from './drawings.utils';
import { productDefinitionActions, useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { publisherStoreActions, usePublisherDataStore } from '../../../../context/DataStore/PublisherDataStore';

interface UseDrawingsState {
  drawingsLoading: boolean;
  handleDrawingsSelection: (drawingsSelection: GridSelectionModel) => void;
  refetchDrawings: () => void;
}

const useDrawings = (): UseDrawingsState => {
  const topLevelFolder = useProductDefinitionStore((state) => state.topLevelFolder);
  const areDrawingFilesFetched = usePublisherDataStore((state) => state.areDrawingFilesFetched);
  const { logAndShowNotification } = useContext(NotificationContext);

  const [isDrawingMerged, setIsDrawingMerged] = useState<boolean>(false);
  const [drawingsLoading, setDrawingsLoading] = useState<boolean>(false);
  const [fetchedDrawingThumbnails, setFetchedDrawingThumbnails] = useState<DrawingThumbnail[] | undefined>();

  const drawingThumbnailsRef = useRef(useProductDefinitionStore.getState().drawingThumbnails);
  const outputsRef = useRef(useProductDefinitionStore.getState().outputs);

  // We use a reference because we don't want to react when these change
  useEffect(() => {
    useProductDefinitionStore.subscribe((state) => {
      outputsRef.current = state.outputs;
      drawingThumbnailsRef.current = state.drawingThumbnails;
    });
  }, []);

  const handleDrawingsSelection = (drawingsSelection: GridSelectionModel): void => {
    const drawingThumbnailsWithSelectionState: DrawingThumbnail[] | undefined = drawingThumbnailsRef.current?.map(
      (drawingThumbnail) => ({
        ...drawingThumbnail,
        checked: drawingsSelection.includes(drawingThumbnail.relativePath),
      }),
    );
    if (drawingThumbnailsWithSelectionState) {
      productDefinitionActions.setDrawingsThumbnails(drawingThumbnailsWithSelectionState);

      const notSelectedDrawings = drawingThumbnailsWithSelectionState
        .map((drawing) => drawing.relativePath)
        .filter((drawing) => !drawingsSelection.includes(drawing));

      productDefinitionActions.deleteDrawingOutputs(notSelectedDrawings);
    }
  };

  const generateThumbnail = useCallback(
    async (drawingFile: string): Promise<{ base64: string; relativePath: string | null; checked: boolean }> => {
      const image = await getThumbnailImgPath(drawingFile);
      const { base64 } = await checkAndGenerateThumbnailInBase64(image, topLevelFolder);
      return { base64, relativePath: extractRelativeFilePath(topLevelFolder, drawingFile), checked: false };
    },
    [topLevelFolder],
  );

  const fetchDrawingFiles = useCallback(async (): Promise<void> => {
    try {
      setDrawingsLoading(true);

      const drawingFiles = await getDrawingFiles(topLevelFolder);
      const drawingThumbnailsPromises = drawingFiles.map(generateThumbnail);
      const results = await Promise.allSettled(drawingThumbnailsPromises);

      const successfulDrawingThumbnails = results
        .filter(isFulfilled)
        .map((result) => result.value)
        .filter((drawing): drawing is DrawingThumbnail => !!drawing.relativePath);

      const failedDrawingImages = results.filter(isRejected);
      if (failedDrawingImages.length) {
        logAndShowNotification({ message: text.notificationFetchGenerateDrawingPathFailed });
      }

      setFetchedDrawingThumbnails(successfulDrawingThumbnails);
      setIsDrawingMerged(false);
    } catch {
      logAndShowNotification({ message: text.notificationGetDrawingFilesFailed });
    } finally {
      publisherStoreActions.setAreDrawingFilesFetched(true);
      setDrawingsLoading(false);
    }
  }, [topLevelFolder, generateThumbnail, logAndShowNotification]);

  useEffect(() => {
    if (topLevelFolder && !areDrawingFilesFetched) {
      fetchDrawingFiles();
    }
  }, [areDrawingFilesFetched, fetchDrawingFiles, topLevelFolder]);

  const getDrawingOutputPaths = (outputs: ProductDefinitionOutput[]) =>
    outputs.reduce<string[]>(
      (paths, output) => (output.options?.drawingTemplatePath ? [...paths, output.options.drawingTemplatePath] : paths),
      [],
    );

  useEffect(() => {
    if (drawingThumbnailsRef.current && fetchedDrawingThumbnails?.length && !isDrawingMerged) {
      const mergedDrawingThumbnails = fetchedDrawingThumbnails.map((fetchedDrawingThumbnail) => {
        const equivalentDrawingInProductDefininition = drawingThumbnailsRef.current?.find(
          (drawingInProductDefininition) =>
            drawingInProductDefininition.relativePath === fetchedDrawingThumbnail.relativePath,
        );

        if (equivalentDrawingInProductDefininition) {
          return equivalentDrawingInProductDefininition;
        }
        return fetchedDrawingThumbnail;
      });

      const drawingThumbnailPaths = mergedDrawingThumbnails.map((drawingThumbnail) => drawingThumbnail.relativePath);
      // If outputs do not exist in drawing thumbnails because of top level folder change or
      // some one remove these drawing from folder, then these outputs need to be removed
      const outputPathsNotIncludedInThumbnails = getDrawingOutputPaths(outputsRef.current).filter(
        (outputPath) => !drawingThumbnailPaths.includes(outputPath),
      );
      productDefinitionActions.deleteDrawingOutputs(outputPathsNotIncludedInThumbnails);

      productDefinitionActions.setDrawingsThumbnails(mergedDrawingThumbnails);
      setIsDrawingMerged(true);
    }
  }, [fetchedDrawingThumbnails, isDrawingMerged]);

  const refetchDrawings = () => publisherStoreActions.setAreDrawingFilesFetched(false);

  return {
    drawingsLoading,
    handleDrawingsSelection,
    refetchDrawings,
  };
};

export default useDrawings;
