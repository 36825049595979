import { AboutInfoMenu, FlexContainer, MiddleButton, Title } from '@mid-react-common/common';
import React from 'react';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { publishTestIds } from '../../../tests/helpers/dataTestIds';
import text from '../../../publisher.text.json';
import { PublishingHeaderContainer } from './PublishingHeader.styles';

interface PublishingHeaderProps {
  title: string;
  titleColor: string;
  subtitle?: string;
  addinVersion?: string;
  desktopApplicationVersion?: string;
  webComponentVersion?: string;
  handleGoBackToProductDefinitions?: () => void;
}

const PublishingHeader: React.FC<PublishingHeaderProps> = ({
  title,
  titleColor,
  webComponentVersion = '',
  subtitle,
  addinVersion,
  desktopApplicationVersion,
  handleGoBackToProductDefinitions,
}): JSX.Element => (
  <PublishingHeaderContainer data-testid={publishTestIds.publishHeader}>
    <div>
      <Title variant="h1" fontWeight="bolder" color={titleColor}>
        {title}
      </Title>
      {subtitle && <Title variant="h3">{subtitle}</Title>}
    </div>
    {handleGoBackToProductDefinitions && (
      <FlexContainer>
        <MiddleButton
          startIcon={<BookmarksIcon />}
          size="small"
          variant="contained"
          onClick={handleGoBackToProductDefinitions}
          data-testid={publishTestIds.buttonProductDefinitions}
        >
          {text.buttonProductDefinitions}
        </MiddleButton>
        <AboutInfoMenu
          isTargetBlank
          webComponentVersion={webComponentVersion}
          addinVersion={addinVersion}
          desktopApplicationVersion={desktopApplicationVersion}
        />
      </FlexContainer>
    )}
  </PublishingHeaderContainer>
);

export default PublishingHeader;
