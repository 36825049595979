import './FormCodeblocks.mutators';
import './FormCodeblocks.blocks';
import { BlocklyPlaceholder } from '../Rules.styles';
import { InputRule, ProductDefinitionInputParameter, SerializedBlocklyWorkspaceState } from 'mid-addin-lib';
import publisherTestIds from '../../../publisher.testids';
import useFormCodeblocks from './useFormCodeblocks';

interface FormCodeblocksProps {
  hidden: boolean;
  inputs: ProductDefinitionInputParameter[];
  formCodeblocksWorkspace?: SerializedBlocklyWorkspaceState;
  setFormCodeBlocksWorkspace: (workspace: SerializedBlocklyWorkspaceState) => void;
  setFormCodeBlocksRules: (rule: InputRule) => void;
  recentlyAdoptedInputs: ProductDefinitionInputParameter[];
  setRecentlyAdoptedInputs: (inputs: ProductDefinitionInputParameter[]) => void;
  isProductConfigurable: boolean;
  setAreFormRulesLoaded: (loaded: boolean) => void;
}

const { blocklyFormWorkspaceTestId } = publisherTestIds;

const FormCodeblocks: React.FC<FormCodeblocksProps> = ({ hidden, ...props }) => {
  const { formWorkspaceDiv } = useFormCodeblocks({ hidden, ...props });

  return <BlocklyPlaceholder ref={formWorkspaceDiv} hidden={hidden} data-testid={blocklyFormWorkspaceTestId} />;
};

export default FormCodeblocks;
