//When Inputs change, we need to add new dropdown options
import Blockly, { BlockSvg, MenuOption } from 'blockly';
import { updateInputFieldDropdown } from '../utils';
import { ProductDefinitionInputParameter } from 'mid-addin-lib';
import {
  ADD_TAB_BLOCK_EXTENSION,
  CONTROL_BLOCK_EXTENSION,
  blocklyLabel,
  blocklyStatementInput,
  formContainerBlock,
  plusImageBase64,
  plusImageAltText,
  plusSignFieldName,
  formTabControlImageSize,
  MAX_TABS_ALLOWED_NUMBER,
} from './FormCodeblocks.constants';
import { addTabBlock, getTabInputContainers, labelValidator } from './FormCodeblocks.utils';
import { blocklyInputsDropdown } from '../constants';
import { getDefaultInputLabel } from 'mid-utils';
import text from '../../../../src/publisher.text.json';
import { InputType } from '@adsk/offsite-dc-sdk';

const exampleDummyInput: ProductDefinitionInputParameter = {
  type: InputType.TEXT,
  value: '',
  unit: 'Text',
  name: text.staticContent.exampleInputLabel,
  label: text.staticContent.exampleInputLabel,
  visible: true,
  readOnly: true,
  allowCustomValue: true,
};

export const initializeFormCodeblocksExtensions = (
  inputParameters: ProductDefinitionInputParameter[],
  isStaticContentWithNoInputs: boolean,
): void => {
  if (Blockly.Extensions.isRegistered(CONTROL_BLOCK_EXTENSION)) {
    Blockly.Extensions.unregister(CONTROL_BLOCK_EXTENSION);
  }

  Blockly.Extensions.register(CONTROL_BLOCK_EXTENSION, function (this: BlockSvg) {
    const inputsDropdown = this.getInput(blocklyInputsDropdown);

    const labelField = this.getField(blocklyLabel);
    if (inputsDropdown && labelField) {
      // We use a dummy input for the dropdown, but this dropdown will be disabled in Blockly
      // It is meant to show the Blockly capability if user selects static content with no inputs
      const inputParametersForDropdown = isStaticContentWithNoInputs ? [exampleDummyInput] : inputParameters;
      const inputDropdownValues: MenuOption[] = inputParametersForDropdown.map((input) => [input.name, input.name]);
      const newInputsDropdown = new Blockly.FieldDropdown(inputDropdownValues);
      newInputsDropdown.setValidator((newValue: string) => labelValidator(this, inputParameters, newValue));
      updateInputFieldDropdown(this, newInputsDropdown, blocklyInputsDropdown);

      if (isStaticContentWithNoInputs) {
        labelField.setValue(exampleDummyInput.label);
      } else if (inputParameters.length > 0) {
        // Set the first value as default label value
        labelField.setValue(getDefaultInputLabel(inputParameters[0]));
      }
    }
  });
};

export const initializeFormTabControlsExtension = (): void => {
  if (Blockly.Extensions.isRegistered(ADD_TAB_BLOCK_EXTENSION)) {
    Blockly.Extensions.unregister(ADD_TAB_BLOCK_EXTENSION);
  }
  Blockly.Extensions.register(ADD_TAB_BLOCK_EXTENSION, function (this: Blockly.BlockSvg) {
    // this refers to the block that the extension is being run on
    const formContainerInput = this.getInput(formContainerBlock);
    const plusSignImageField = formContainerInput?.appendField(
      new Blockly.FieldImage(plusImageBase64, formTabControlImageSize, formTabControlImageSize, plusImageAltText, () => {
        const tabInputs = getTabInputContainers(this.inputList);

        // Max tabs allowed: 12
        if (tabInputs.length === MAX_TABS_ALLOWED_NUMBER) {
          const plusSignField = plusSignImageField?.fieldRow.find((field) => field.name === plusSignFieldName);
          plusSignField?.setTooltip(text.blocklyFormTab.maxTabLimitReached);
          return;
        }

        // Detach any existing child blocks if going from 0 tabs to one tab
        if (tabInputs.length === 0) {
          const existingStatementBlocks = this.inputList.filter((input) => input.name === blocklyStatementInput);

          if (existingStatementBlocks.length !== 0) {
            existingStatementBlocks.forEach((block) => block.connection?.disconnect());
            this.removeInput(blocklyStatementInput);
          }
        }

        // Add a new tab block
        const tabBlockNumber = tabInputs.length + 1;
        const _addTabBlock = addTabBlock.bind(this);
        _addTabBlock(tabBlockNumber);
      }),
      plusSignFieldName,
    );
  });
};
