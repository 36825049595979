import { AddinContainer, EnvironmentInfo } from '@mid-react-common/addins';
import {
  AppLoadingProgress,
  ConfirmationModal,
  ErrorBoundary,
  ErrorFallback,
  InventorWebComponentMoniker,
  ModalContext,
  NotificationsProvider,
  QueryClientProvider,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  darkTheme,
  lightTheme,
  useModalStore,
  useNotificationStore,
} from '@mid-react-common/common';
import CssBaseline from '@mui/material/CssBaseline';
import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { browserApiService, getCurrentAuthToken, getEnvironment } from 'mid-addin-lib';
import { InversifyTypes, inversifyContainer, registerApiBaseURL, registerAuthHandler, registerEnv } from 'mid-api-services';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';
import React, { useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import { usePublisherDataStore } from './context/DataStore/PublisherDataStore';
import { useUnsavedProductDefinitionChangesTracker } from './context/DataStore/unsavedChangesTracker.utils';
import NavigationContext from './context/NavigationStore/Navigation.context';
import { useNavigationStore } from './context/NavigationStore/navigationStore';
import TabProgressContext from './context/TabProgressStore/TabProgress.context';
import { useTabProgressStore } from './context/TabProgressStore/tabProgressStore';
import { productDefinitionActions, useProductDefinitionStore } from './context/DataStore/productDefinitionStore';
import { CustomizerDataContext } from 'context/Customizers/customizerData.context';
import AuthFusion from './Fusion/customFunctions/AuthFusion';

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY_ADDINS || '',
  moniker: InventorWebComponentMoniker,
  appVersion:
    import.meta.env.VITE_PUBLISHER_APP === 'fusion'
      ? import.meta.env.VITE_FSNW_VERSION
      : import.meta.env.VITE_IVTW_VERSION || 'Not provided',
});

const CustomizerComponents = React.lazy(() => {
  if (import.meta.env.VITE_PUBLISHER_APP === 'fusion') {
    return import('./Fusion/CustomizerComponents');
  }
  return import('./Inventor/CustomizerComponents');
});

const App: React.FC = (): JSX.Element => {
  const { initialProductDefinitionData } = useContext(CustomizerDataContext);
  const { enableMaintenanceMode, enableDarkModeSwitch, inventorWebComponentUnderMaintenance } = useFlags();
  const [dark, setDark] = useState<boolean>(false);

  const { sessionId, recentlyAdoptedInputs } = usePublisherDataStore(
    useShallow((state) => ({
      recentlyAdoptedInputs: state.recentlyAdoptedInputs,
      sessionId: state.sessionId,
    })),
  );

  const { topLevelFolder, inventorProject, assembly, inputs, outputs, rules, isConfigurable } = useProductDefinitionStore(
    useShallow((state) => ({
      topLevelFolder: state.topLevelFolder,
      inventorProject: state.inventorProject,
      assembly: state.assembly,
      inputs: state.inputs,
      outputs: state.outputs,
      rules: state.rules,
      isConfigurable: state.isConfigurable,
    })),
  );

  const navigationStore = useNavigationStore();
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();

  const tabProgressStore = useTabProgressStore({
    topLevelFolder,
    inventorProject,
    assembly,
    inputs,
    outputs,
    rules,
    recentlyAdoptedInputs,
    isConfigurable,
  });
  useUnsavedProductDefinitionChangesTracker();

  const [apiServicesInitialized, setApiServicesInitialized] = useState(false);

  useEffect(() => {
    const registerDependency = async () => {
      const env = await getEnvironment();

      const dcApiBaseURL = await browserApiService.getDcApiUrl();
      const forgeApiBaseUrl = ServiceConfigMap[ServiceTypes.FORGE_API][env];
      const accBridgeBaseUrl = ServiceConfigMap[ServiceTypes.ACC_BRIDGE][env];

      if (!inversifyContainer.isBound(InversifyTypes.Env)) {
        registerEnv(env);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
        registerAuthHandler(() => {
          if (import.meta.env.VITE_PUBLISHER_APP === 'fusion') {
            return AuthFusion.getOAuth2Token();
          }
          return getCurrentAuthToken();
        });
      }

      if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.DcApiBaseURL, dcApiBaseURL);
      }

      if (!inversifyContainer.isBound(InversifyTypes.ForgeApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.ForgeApiBaseURL, forgeApiBaseUrl.api);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AccBridgeApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.AccBridgeApiBaseURL, accBridgeBaseUrl.api);
      }

      setApiServicesInitialized(true);
    };

    registerDependency();
  }, []);

  useEffect(() => {
    // Set initial data store values
    productDefinitionActions.setProductDefinition(initialProductDefinitionData);
  }, [initialProductDefinitionData]);

  const handleResetAppState = (): void => window.location.reload();

  const handleThemeChange = () => {
    setDark((state) => !state);
  };

  const appTree = (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />

      <ModalContext.Provider value={modalStore}>
        <ConfirmationModal isAddin />
        <AddinContainer>
          <NotificationsProvider store={notificationStore}>
            <QueryClientProvider>
              {apiServicesInitialized && sessionId ? (
                <UserAnalyticsProvider webComponentMoniker={InventorWebComponentMoniker} sessionId={sessionId}>
                  {enableMaintenanceMode || inventorWebComponentUnderMaintenance ? (
                    <UnderMaintenance />
                  ) : (
                    <NavigationContext.Provider value={navigationStore}>
                      <TabProgressContext.Provider value={tabProgressStore}>
                        {enableDarkModeSwitch && (
                          <Switch
                            onChange={handleThemeChange}
                            sx={{ position: 'absolute', top: 0, left: '50%', zIndex: 1000 }}
                          />
                        )}
                        <CustomizerComponents>
                          <Header />
                          <EnvironmentInfo hostname={window.location.host} />
                          <ScreenManager />
                        </CustomizerComponents>
                      </TabProgressContext.Provider>
                    </NavigationContext.Provider>
                  )}
                </UserAnalyticsProvider>
              ) : (
                <AppLoadingProgress />
              )}
            </QueryClientProvider>
          </NotificationsProvider>
        </AddinContainer>
      </ModalContext.Provider>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
