import { TableRow, TableCell, FormControlLabel, Switch, TableHead, TableBody } from '@mui/material';
import { OutputRepresentationsTable } from '../OutputsTab.styles';
import text from 'publisher.text.json';
import testIds from 'publisher.testids';
import { OutputProps } from '../Output.types';

interface GroupedOutputRepresentationsProps {
  outputs: OutputProps[];
}

export const GroupedOutputRepresentations: React.FC<GroupedOutputRepresentationsProps> = ({ outputs }) => {
  const representationControls = outputs.map((output) =>
    output.representations.map((representation, index) => {
      const isSelected = output.selectedRepresentations.includes(representation);
      const handleOutputRepresentationChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        output.handleRepresentationChange(output.outputType, event.target.name, checked);
      };
      return (
        <TableRow key={`${output.outputType}-${representation}`}>
          <TableCell>{index === 0 ? output.fileType || output.outputType : null}</TableCell>
          <TableCell>{representation}</TableCell>
          <TableCell align="right">
            <FormControlLabel
              data-testid={`${testIds.outputStatusPrefix}-${representation}`}
              labelPlacement="start"
              label={isSelected ? text.outputAvailable : text.outputUnavailable}
              control={
                <Switch
                  name={representation}
                  disabled={output.disabled}
                  checked={isSelected}
                  onChange={handleOutputRepresentationChange}
                />
              }
            />
          </TableCell>
        </TableRow>
      );
    }),
  );

  return (
    <OutputRepresentationsTable size="small">
      <TableHead>
        <TableRow>
          <TableCell>{text.outputTypeColumn}</TableCell>
          <TableCell>{text.outputRepresentation}</TableCell>
          <TableCell align="right">{text.outputStatus}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{representationControls}</TableBody>
    </OutputRepresentationsTable>
  );
};
