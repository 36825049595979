import { GridSelectionModel } from '@mui/x-data-grid';
import text from 'publisher.text.json';
import {
  createFullPath,
  InventorProperties,
  ProductDefinitionInputParameter,
  toProductDefinitionInputParameter,
} from 'mid-addin-lib';
import { useLogAndShowNotification } from '@mid-react-common/common';
import { useEffect, useState } from 'react';
import { fetchAllInventorData } from './utils';
import useParametersDefaults from './useParametersDefaults';
import { useQuery } from '@tanstack/react-query';
import { productDefinitionActions, useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { publisherStoreActions, usePublisherDataStore } from '../../../../context/DataStore/PublisherDataStore';
import { useShallow } from 'zustand/react/shallow';
import { getProductConfigurableStatus } from 'mid-utils';

export type HandleSelectedInputUpdateType = (
  input: ProductDefinitionInputParameter,
  property: { [key: string]: any },
) => void;

export interface UseInputsSelectionState {
  inventorData: InventorProperties | undefined;
  productDefinitionInputs: ProductDefinitionInputParameter[];
  selectedParameters: ProductDefinitionInputParameter[];
  setSelectedParameters: (names: GridSelectionModel) => void;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
  loading: boolean;
  error: Error | null;
  isProductConfigurable: boolean;
}

const FETCH_ALL_INVENTOR_DATA_KEY = 'fetchAllInventorData';

const useInputsSelection = (): UseInputsSelectionState => {
  const { inputs, topLevelFolder, assembly, isConfigurable } = useProductDefinitionStore(
    useShallow((state) => ({
      inputs: state.inputs,
      topLevelFolder: state.topLevelFolder,
      assembly: state.assembly,
      isConfigurable: state.isConfigurable,
    })),
  );

  const recentlyAdoptedInputs = usePublisherDataStore((state) => state.recentlyAdoptedInputs);

  const [iamFileFullPath, setIamFileFullPath] = useState<string | undefined>();
  const [convertedInputs, setConvertedInputs] = useState<{
    parameters: ProductDefinitionInputParameter[];
  }>({ parameters: [] });

  const [localSelectedParameters, setLocalSelectedParameters] = useState<ProductDefinitionInputParameter[]>(inputs);

  // Initial Setup, getting assembly from currentProductDefinition, which is needed to fetch Inventor Data
  useEffect(() => {
    if (topLevelFolder && assembly) {
      setIamFileFullPath(createFullPath(topLevelFolder, assembly));
    }
  }, [topLevelFolder, assembly]);

  const {
    data: inventorData,
    isPending: loading,
    error,
  } = useQuery({
    queryKey: [FETCH_ALL_INVENTOR_DATA_KEY],
    queryFn: iamFileFullPath ? () => fetchAllInventorData(iamFileFullPath) : undefined,
    enabled: !!iamFileFullPath,
  });

  useLogAndShowNotification(error, text.notificationGetPartOrAssemblyPropertiesFailed);

  useParametersDefaults({ inventorData });

  // Convert Inventor data to productDefinitionStore interface
  useEffect(() => {
    if (inventorData) {
      const convertedParameters = inventorData.parameters.map((param) => toProductDefinitionInputParameter(param));

      setConvertedInputs({ parameters: convertedParameters });
    }
  }, [inventorData]);

  const setSelectedParameters = (names: GridSelectionModel) => {
    // New parameters being added
    const selectedParameters = convertedInputs.parameters.filter((input) => names.includes(input.name));
    setLocalSelectedParameters(selectedParameters);

    // Find all existing productDefinitionStore inputs that intersect with the selected parameters
    const existingProductDefinitionInputs = inputs.filter((oldInput) =>
      selectedParameters.some((param) => param.name === oldInput.name),
    );

    // Find selected parameters that are not already in the existing productDefinitionStore inputs
    const newInputs = selectedParameters.filter(
      (newInput) => !existingProductDefinitionInputs.some((existingInput) => existingInput.name === newInput.name),
    );

    const filterRemovedItems = selectedParameters.filter((input) =>
      recentlyAdoptedInputs.some((param) => param.name === input.name),
    );
    const updatedRecentlyAdoptedInputs = [...filterRemovedItems, ...newInputs];
    publisherStoreActions.setRecentlyAdoptedInputs(updatedRecentlyAdoptedInputs);

    productDefinitionActions.replaceAllInputs([...existingProductDefinitionInputs, ...newInputs]);
  };

  const handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType = (input, property) => {
    productDefinitionActions.updateParameter(input, property);
  };

  return {
    inventorData,
    productDefinitionInputs: inputs,
    selectedParameters: localSelectedParameters,
    loading,
    error,
    setSelectedParameters,
    handleSelectedInputDataStoreUpdate,
    isProductConfigurable: getProductConfigurableStatus(isConfigurable),
  };
};

export default useInputsSelection;
