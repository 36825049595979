import {
  ProductDefinition,
  ProductDefinitionsCrudUtils,
  createFullPath,
  PublishUtils,
  openProductDefinitionDocument,
  ProductDefinitionInputParameter,
} from 'mid-addin-lib';
import text from 'publisher.text.json';

interface UpdateModelInputsArgs {
  topLevelFolder: string;
  assembly: string;
  inputs: ProductDefinitionInputParameter[];
}

const updateModelInputs = async ({ topLevelFolder, assembly, inputs }: UpdateModelInputsArgs): Promise<boolean> => {
  const documentFilePath = createFullPath(topLevelFolder, assembly);
  const transformedInputs = PublishUtils.productDefinitionInputsToInventorInputs(inputs);
  const success = await openProductDefinitionDocument(documentFilePath, transformedInputs);
  if (!success) {
    throw new Error(text.inventorModelUpdateFailed);
  }
  return success;
};

export const updateModelAndProductDefinitionInputs = async (
  productDefinition: ProductDefinition,
): Promise<ProductDefinition> => {
  // We upsert the currentProductDefinition regardless of the
  // outcome of updating the model
  const upsertedProductDefinition = await ProductDefinitionsCrudUtils.upsertProductDefinition(productDefinition);
  const { topLevelFolder, assembly, inputs } = upsertedProductDefinition;
  await updateModelInputs({ topLevelFolder, assembly, inputs });
  return upsertedProductDefinition;
};
