import Divider from '@mui/material/Divider';
import { useEffect, useRef, useState } from 'react';
import { BlocklyWrapper } from './Rules.styles';
import { ProductFormPreview } from './ProductFormPreview/ProductFormPreview';
import FormCodeblocks from './FormCodeblocks/FormCodeblocks';
import { CodeblocksWorkspaceType } from './types';
import WorkspaceSelector from './Components/WorkspaceSelector';
import { getSelectedWorkspace } from './utils';
import InputCodeblocks from './InputCodeblocks/InputCodeblocks';
import { productDefinitionActions, useProductDefinitionStore } from '../../context/DataStore/productDefinitionStore';
import { publisherStoreActions, usePublisherDataStore } from '../../context/DataStore/PublisherDataStore';
import { useShallow } from 'zustand/react/shallow';
import { getProductConfigurableStatus } from 'mid-utils';

const Rules: React.FC = (): JSX.Element => {
  const { productDefinitionName, topLevelFolder, codeBlocksWorkspace, formCodeBlocksWorkspace, isConfigurable } =
    useProductDefinitionStore(
      useShallow((state) => ({
        productDefinitionName: state.name,
        topLevelFolder: state.topLevelFolder,
        codeBlocksWorkspace: state.codeBlocksWorkspace,
        formCodeBlocksWorkspace: state.formCodeBlocksWorkspace,
        isConfigurable: state.isConfigurable,
      })),
    );

  const { recentlyAdoptedInputs, latestWorkspaceSelected, backpackContents } = usePublisherDataStore(
    useShallow((state) => ({
      recentlyAdoptedInputs: state.recentlyAdoptedInputs,
      latestWorkspaceSelected: state.latestWorkspaceSelected,
      backpackContents: state.backpackContents,
    })),
  );

  const selectedWorkspace = getSelectedWorkspace(latestWorkspaceSelected);

  const inputsRef = useRef(useProductDefinitionStore.getState().inputs);
  useEffect(() => useProductDefinitionStore.subscribe((state) => (inputsRef.current = state.inputs)), []);

  const isProductConfigurable = getProductConfigurableStatus(isConfigurable);
  const isStaticContentWithNoInputs = !isProductConfigurable && inputsRef.current.length === 0;

  // Static content does not render InputCodeblocks to trigger input rules validation. We need to initialize areInputRulesLoaded to true because
  // static content does not render input workspace.
  const [areInputRulesLoaded, setAreInputRulesLoaded] = useState(!isProductConfigurable);
  // For Static content with no inputs, we need to set areFormRulesLoaded initial state to true because it will clear all blocks from form Form workspace.
  const [areFormRulesLoaded, setAreFormRulesLoaded] = useState(isStaticContentWithNoInputs);

  const [updateFormEnabled, setUpdateFormEnabled] = useState(false);
  const [highlightedBlockId, setHighlightedBlockId] = useState<string | undefined>(undefined);
  const getCodeRef = useRef<(() => string) | undefined>();

  return (
    <BlocklyWrapper>
      <WorkspaceSelector
        selectedWorkspace={selectedWorkspace}
        setSelectedWorkspace={publisherStoreActions.setLatestWorkspaceSelected}
      />
      {isProductConfigurable && (
        <InputCodeblocks
          inputCodeblocksWorkspace={codeBlocksWorkspace}
          hidden={selectedWorkspace !== CodeblocksWorkspaceType.INPUT}
          inputs={inputsRef.current}
          backpackContents={backpackContents}
          productDefinitionName={productDefinitionName}
          productDefinitionTopLevelFolder={topLevelFolder}
          highlightedBlockId={highlightedBlockId}
          setInputsCodeBlocksRules={productDefinitionActions.setRule}
          setInputsCodeBlocksWorkspace={productDefinitionActions.setInputCodeBlocksWorkspace}
          setBackpackContents={publisherStoreActions.setBackpackContents}
          setUpdateFormEnabled={setUpdateFormEnabled}
          setAreInputRulesLoaded={setAreInputRulesLoaded}
          getCodeRef={getCodeRef}
        />
      )}
      <FormCodeblocks
        hidden={selectedWorkspace !== CodeblocksWorkspaceType.FORM}
        inputs={inputsRef.current}
        formCodeblocksWorkspace={formCodeBlocksWorkspace}
        recentlyAdoptedInputs={recentlyAdoptedInputs}
        setFormCodeBlocksWorkspace={productDefinitionActions.setFormCodeBlocksWorkspace}
        setFormCodeBlocksRules={productDefinitionActions.setRule}
        setRecentlyAdoptedInputs={publisherStoreActions.setRecentlyAdoptedInputs}
        isProductConfigurable={isProductConfigurable}
        setAreFormRulesLoaded={setAreFormRulesLoaded}
      />
      <Divider orientation="vertical" />
      <ProductFormPreview
        updateFormEnabled={updateFormEnabled}
        selectedWorkspace={selectedWorkspace}
        areRulesLoaded={areFormRulesLoaded && areInputRulesLoaded}
        getCodeRef={getCodeRef}
        setHighlightedBlockId={setHighlightedBlockId}
        setUpdateFormEnabled={setUpdateFormEnabled}
      />
    </BlocklyWrapper>
  );
};

export default Rules;
