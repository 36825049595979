import text from 'publisher.text.json';
import { PublishStatus } from 'mid-addin-lib';
import { FlexContainer, LocationContainer, ReleaseNotes, Spacing } from '@mid-react-common/common';
import { ACCDocSelection, ProductFolderBrowser, useACCDocSelection } from '@mid-react-common/addins';
import React, { useContext, useEffect, useRef, useState } from 'react';
import testIds from '../../publisher.testids';
import {
  ProductName,
  SectionTitle,
  ReleaseNotesWrapper,
  Wrapper,
  productFolderBrowserHeight,
  ReleaseToBeTitle,
  ReleaseToBeContainer,
  ThreeDotsLoader,
} from './Publishing.styles';
import { PublishingComplete } from './PublishingComplete';
import { PublishingFailed } from './PublishingFailed';
import { PublishingLoading } from './PublishingLoading';
import { usePublishing } from './usePublishing';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IconButton from '@mui/material/IconButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useProductDefinitionStore } from '../../context/DataStore/productDefinitionStore';
import { usePublisherDataStore } from '../../context/DataStore/PublisherDataStore';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomComponentsContext from 'context/Customizers/customizerComponents.context';

const RELEASE_TO_BE_ANIMATION_TIMEOUT = 500;

export const Publishing: React.FC = (): JSX.Element => {
  const { PublishPrereqsComponent } = useContext(CustomComponentsContext);
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();

  const {
    products,
    productsLoading,
    incomingAccBridgeProducts,
    bridgeProductsLoading,
    productsError,
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeItem,
    publishResponse,
    productNameToPublish,
    handleSelectFolder,
    handleProductNameChange,
    handleProductNameDoubleClick,
    publishFolderPermission,
    productNameErrors,
    releaseNumberToBe,
    shouldShowReleaseNumberToBe,
    onReleaseNotesChange,
    releaseNumberToBeLoading,
    productNameIconErrorText,
  } = usePublishing({ selectedAccount, selectedProject });

  const productDefinitionRef = useRef(useProductDefinitionStore.getState());

  // We use a reference because we don't want to react when these change
  useEffect(() => {
    useProductDefinitionStore.subscribe((state) => {
      productDefinitionRef.current = state;
    });
  }, []);

  const currentPublishStatus = usePublisherDataStore((state) => state.currentPublishStatus);

  const [accDocSelectionDropdownOpen, setAccDocSelectionDropdownOpen] = useState<boolean>(false);

  const { enableStaticContent } = useFlags();

  const renderPublishStatusComponents = (status: PublishStatus) => {
    switch (status) {
      case PublishStatus.IDLE:
        return (
          <LocationContainer>
            <SectionTitle variant="h2" gutterBottom>
              {text.publishLocationTitle}
            </SectionTitle>

            <br />

            <FlexContainer>
              <ProductName
                label={text.publishProductName}
                helperText={productNameErrors || text.publishReleaseDetailsInformation}
                size="small"
                onChange={handleProductNameChange}
                value={productNameToPublish}
                inputProps={{ 'data-testid': testIds.publishProductName }}
                error={!!productNameErrors}
                InputProps={
                  productNameIconErrorText
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={productNameIconErrorText} arrow>
                              <IconButton>
                                <ErrorOutlineIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />

              {shouldShowReleaseNumberToBe && (
                <ReleaseToBeContainer data-testid={testIds.releaseToBeContainer}>
                  <Tooltip placement="bottom-start" title={text.releaseToBeTooltip} arrow>
                    <IconButton disableRipple disableFocusRipple>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                  <ReleaseToBeTitle>
                    {text.releaseToBeTitle} {''}
                    {productsLoading || releaseNumberToBeLoading || accDocSelectionDropdownOpen ? (
                      <ThreeDotsLoader>...</ThreeDotsLoader>
                    ) : (
                      releaseNumberToBe
                    )}
                  </ReleaseToBeTitle>
                  {text.releaseToBeText}
                </ReleaseToBeContainer>
              )}
            </FlexContainer>

            <Spacing />

            {accountsLoading && <CircularProgress />}
            {accounts && (
              <>
                <FlexContainer>
                  <ACCDocSelection
                    accounts={accounts}
                    accountsLoading={accountsLoading}
                    projects={projects || []}
                    projectsLoading={projectsLoading}
                    selectedAccount={selectedAccount}
                    selectedProject={selectedProject}
                    projectsDisabled={projectsDisabled}
                    handleSelectAccount={handleSelectAccount}
                    handleSelectProject={handleSelectProject}
                    maxHeight={productFolderBrowserHeight}
                    handleOpen={() => setAccDocSelectionDropdownOpen(true)}
                    handleClose={() =>
                      // timeout to avoid "release to be" flickering when closing dropdown
                      setTimeout(() => setAccDocSelectionDropdownOpen(false), RELEASE_TO_BE_ANIMATION_TIMEOUT)
                    }
                  />
                </FlexContainer>
                <ProductFolderBrowser
                  projectId={selectedProject?.id || null}
                  projectPlatform={selectedProject?.platform || null}
                  rootFolders={rootFoldersTreeItems}
                  rootFoldersLoading={rootFoldersLoading}
                  rootFoldersError={rootFoldersError}
                  selectedFolderTreeElement={selectedFolderTreeItem}
                  displayCreateNewFolderButton
                  products={products}
                  incomingAccBridgeProducts={incomingAccBridgeProducts}
                  productsLoading={productsLoading || bridgeProductsLoading}
                  productsError={productsError}
                  onFolderClick={handleSelectFolder}
                  folderPermissionFilter={publishFolderPermission}
                  maxHeight={productFolderBrowserHeight}
                  onProductDoubleClick={handleProductNameDoubleClick}
                />
              </>
            )}

            <Spacing />

            <SectionTitle variant="h2" gutterBottom>
              {text.publishReleaseNotesTitle}
            </SectionTitle>

            <ReleaseNotesWrapper>
              <ReleaseNotes onChange={onReleaseNotesChange} defaultNotes={productDefinitionRef.current.notes} />
            </ReleaseNotesWrapper>
          </LocationContainer>
        );
      case PublishStatus.INVALID:
        return PublishPrereqsComponent;
      case PublishStatus.LOADING:
        return (
          <Wrapper>
            <PublishingLoading
              currentProductDefinition={productDefinitionRef.current}
              publishedProductName={productNameToPublish}
              enableStaticContent={enableStaticContent}
            />
          </Wrapper>
        );
      case PublishStatus.COMPLETE:
        return (
          <Wrapper>
            <PublishingComplete
              currentProductDefinition={productDefinitionRef.current}
              publishedProductName={productNameToPublish}
              release={publishResponse?.publishedProduct?.release}
              enableStaticContent={enableStaticContent}
            />
          </Wrapper>
        );
      case PublishStatus.FAILURE:
        return (
          <Wrapper>
            <PublishingFailed
              currentProductDefinition={productDefinitionRef.current}
              publishedProductName={productNameToPublish}
              enableStaticContent={enableStaticContent}
            />
          </Wrapper>
        );
      default:
        <></>;
    }
  };

  return <>{renderPublishStatusComponents(currentPublishStatus)}</>;
};
